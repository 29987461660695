import { PlusSmIcon } from '@heroicons/react/solid'
import { useState, useEffect } from 'react'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import ProgressGaugeLarge from '../components/charts/ProgressGaugeLarge'
import StatusBarLarge from '../components/charts/StatusBarLarge'
import {machineData} from '../MachineTestData'
import SelectedAssetPageOverviewTab from './SelectedAssetPageOverviewTab'
import SelectedAssetPageHealthTab from './SelectedAssetPageHealthTab'
import { motion, AnimatePresence } from "framer-motion";
import SelectedAssetPageHistoryTab from './SelectedAssetPageHistoryTab'
import AutoClassifyOperationsModal from '../components/modals/assetmodals/AutoClassifyOperationsModal'
import SetAssetNotificationsModal from '../components/modals/assetmodals/SetAssetNotificationsModal'
import SetAssetTimeNotification from '../components/modals/assetmodals/SetAssetTimeNotification'
import { globalSelectedOrganization } from '../atoms/orgStates'
import { useRecoilValue } from 'recoil'
import { getData } from '../ApiCalls/DataApis'
import { apiLink } from '../ApiCalls/ApisVariables'
import TimeAgo from 'timeago-react';
import OptimizeLargeImage from '../components/OptimizeLargeImage';
import moment from 'moment'
import MqttService from '../core/services/MqttService'; 
import EditAssetModal from '../components/modals/assetmodals/EditAssetModal'
import UptimeBarChartLarge from '../components/charts/UptimeBarChartLarge'
import ShiftFilterBtn from '../components/filterButtons/ShiftFilterBtn'
import { useRef } from 'react';
import { userAuthorization } from '../atoms/userStates'
import SenseAiLightGray from '../images/SenseAiLightGray.svg'
import SenseAiCyan from '../images/SenseAiCyan.svg'
import SenseAiOrange from '../images/SenseAiOrange.svg'
import { darkModeState } from '../atoms/darkModeState'
import EditSensorModal from '../components/modals/sensorModals/EditSensorModal'
import OeeAssetGoalModal from '../components/modals/assetmodals/OeeAssetGoalModal'
import OeeGaugeLarge from '../components/charts/OeeGaugeLarge'
import { LinearProgress } from '@mui/material'


const subNav = [
    {
        name:'Overview',
        url:'overview'
    },
    {
        name:'Health',
        url:'health' 
    },
    // {
    //     name:'History',
    //     url:'history'
    // },
    // {
    //     name:'Insights',
    //     url:'insights'
    // },
]


function SelectedAssetPage() {
    const selectedOrganization = useRecoilValue(globalSelectedOrganization);
    const isUserAuthorized = useRecoilValue(userAuthorization);
    const isDark = useRecoilValue(darkModeState);
    const navigate = useNavigate();
    const { assetId } = useParams()
    const [selectedAsset, setSelectedAsset] = useState([])
    const [selectedSubNav, setSelectedSubNav] = useState('overview')
    const [selectedTab, setSelectedTab] = useState(subNav[0])
    const [assetActivity, setAssetActivity] = useState([])
    const [isAssetRunning, setIsAssetRunning] = useState(false)
    const [barChartCategoriesAll, setBarChartCategoriesAll] = useState([])
    const [statusBarData, setStatusBarData] = useState([])
    const [oeeCalc, setOeeCalc] = useState(0)
    const [uptime, setUptime] = useState('0:00h')
    const [downtime, setDowntime] = useState('0:00h')
    const [assetHistoryActivity, setAssetHistoryActivity] = useState([])
    const [timePassed, setTimePassed] = useState(0)
    const [timePassedDate, setTimePassedDate] = useState('')
    const [isRunningRealTime, setIsRunningRealTime] = useState(null)
    const [isMobile, setIsMobile] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const [pauseMqtt, setPauseMqtt] = useState(false)
    const previousPausedMqttRef = useRef();
    const location = useLocation();
    const [operationsList, setOperationsList] = useState([])
    const [downtimeCategories, setDowntimeCategories] = useState([])
    const [updateParetoDowntimeChart, setUpdateParetoDowntimeChart] = useState(false)
    const [openEditSensorModal, setOpenEditSensorModal] = useState(false)
    const [openAssetOeeGoalModal, setOpenAssetOeeGoalModal] = useState(false)
    const [assetOeeGoal, setAssetOeeGoal] = useState('') 
    const [loadingStatusBar, setLoadingStatusBar] = useState(false)
    
    const lastRefreshTimeRef = useRef(0);
    const refreshScheduledRef = useRef(false);


    useEffect(() => {
      let currentIsMobile = searchParams.get('platform')
      if(currentIsMobile === 'mobile'){
        setIsMobile(true)
      }else{
        setIsMobile(false)
      }
    },[])


    useEffect(() =>{
        setSelectedSubNav(selectedTab.url)
    },[selectedTab])

    function handleRefreshLogic() {
        const now = Date.now();
        const timeSinceLastRefresh = now - lastRefreshTimeRef.current;
    
        if (timeSinceLastRefresh >= 5000) {
          // Refresh all APIs immediately
          getTotalHourlyCount()
          getShiftUtilizations()
          getUtilizationOperation()
          getActivityState()
          lastRefreshTimeRef.current = now;
          refreshScheduledRef.current = false;
        } else if (!refreshScheduledRef.current) {
          // Schedule refresh after remaining time
          const timeToWait = 5000 - timeSinceLastRefresh;
          refreshScheduledRef.current = true;
    
          setTimeout(() => {
            getTotalHourlyCount()
            getShiftUtilizations()
            getUtilizationOperation()
            getActivityState()
            lastRefreshTimeRef.current = Date.now();
            refreshScheduledRef.current = false;
          }, timeToWait);
        }
      }
    


    useEffect(() =>{
        try {
            if(selectedOrganization && assetId){

                getData(apiLink + '/v1/assets/'+ assetId  )
                .then(async response => {
                    // JSON data parsed by response.json() call
                    if(response.ok ){
                    const data = await response.json();
                        if(data && data.data){
                            setSelectedAsset(data.data[0])
                            setAssetOeeGoal(data.data[0].asset_oee_goal)
                        }
                    }
                })

                // Get list of operations 
                getData(apiLink + '/v1/organizations/' + selectedOrganization + '/operations')
                .then(async response => {
                if(response.ok ){
                    const data = await response.json();
                    if(data && data.data){
                        let ops = data.data
                        ops.unshift(
                            {
                                "operation_name": "UNCLASSIFIED", 
                                "operation_uuid": "unclassified"
                            } 
                        )
                    setOperationsList(ops)
                    }
                }
                })

                refreshDowntimeCategories()
            }
            
        } catch (error) {
            
        }
    },[selectedOrganization, assetId])
 
    useEffect(() => {
        try {
            if(selectedOrganization && assetId ){
                
                getTotalHourlyCount()

                setTimeout(() => {
                    getShiftUtilizations()
                }, 1000)

               

                // Waiting for other functions to be done before setting the activity list (It's faster but idk if its the proper way of doing this)
                setTimeout(() => {
                    getUtilizationOperation()
                }, 1000)
            }
            
        } catch (error) {
          console.log(error.message)
        }
    },[selectedOrganization, assetId, location])


    


    useEffect(() => {
        try {
            if(selectedAsset && Object.keys(selectedAsset).length > 0){
                setTimeout(() => {
                    //  MQTT Subcribe - We need a delay to ensure MQTT is connected before trying to subscribe
                    if(MqttService.isConnected && selectedAsset.node_subscription_topic) {
                        MqttService.subscribe(selectedAsset.node_subscription_topic, onMQTTReceived)
                    }
                  }, 3000)
               
            }
            
        } catch (error) {
            
        }

    }, [selectedAsset])

    

    const onMQTTReceived = message => {
        try{
            var payloadMessage = message.payloadString
            // console.log('Outside MQTT asset', payloadMessage)
            try { payloadMessage = JSON.parse(payloadMessage) } catch(e){}
            if(!previousPausedMqttRef.current && payloadMessage && payloadMessage.data && payloadMessage.data.running !== undefined ){
                // console.log('Inside MQTT asset', payloadMessage)
                
                handleRefreshLogic();
                if(payloadMessage.data.running === false){
                    setIsRunningRealTime(false)
                    //  using this setMqttLastOp date to update how much time has gone by since last operation for the TimePassed
                    
                }else{
                    setIsRunningRealTime(true)
                }
            }
          }
          catch(e){
            console.log("Error in reading MQTT");
            console.log(e.message);
          }
    }

    

    useEffect(() => {
        previousPausedMqttRef.current = pauseMqtt;
      }, [pauseMqtt]);

    const handleToggleMqtt = (isPaused) => {
        setPauseMqtt(isPaused)
    }
    

    useEffect(() => {
        try {
            if(assetId && selectedAsset && Object.keys(selectedAsset).length > 0){
                getActivityState()
            }
            
        } catch (error) {
            
        }

    }, [selectedAsset, assetId])

  

    const getOrgAssets = () => {
        getData(apiLink + '/v1/assets/'+ assetId  )
            .then(async response => {
                // JSON data parsed by response.json() call
                if(response.ok ){
                const data = await response.json();
                    if(data && data.data){
                        setSelectedAsset(data.data[0])
                        setAssetOeeGoal(data.data[0].asset_oee_goal)
                    }
                }
            })
    }

    const getTotalHourlyCount = () => {

        try {
            if(selectedOrganization && assetId ){
                let currentSearch = location.search
                getData(apiLink + '/v1/assets/'+ assetId + '/total-hourly-count' + currentSearch)
                .then(async response => {
                    // JSON data parsed by response.json() call
                    if(response.ok ){
                    const data = await response.json();
                        if(data && data.data){
                            let timeCategoriesAll = []
                            let timeCategoriesTrimmed = []
                            let lastDataIndex = Object.keys(data.data.total_hourly_count).length -1
                            data.data.total_hourly_count.map((hourCount, index) => {
                                let time = ''
                                if(index === 0) {
                                    time = formatAMPM(hourCount.hr)
                                    timeCategoriesTrimmed.push(time)
                                }else if(index === lastDataIndex){
                                    time = formatAMPM(hourCount.hr)
                                    timeCategoriesTrimmed.push(time)
                                }else{
                                    timeCategoriesTrimmed.push(time)
                                }

                                let currentTime = formatAMPM(hourCount.hr)
                                timeCategoriesAll.push(currentTime)


                            })
                            setBarChartCategoriesAll(timeCategoriesAll)
                        }
                    }
                })
            }
        }catch (error){
            console.log(error.message)
        }

    }

    const getShiftUtilizations = () => {

        try {
            if(selectedOrganization && assetId && !loadingStatusBar ){
                setLoadingStatusBar(true)
                let currentSearch = location.search
                
                getData(apiLink + '/v1/assets/'+ assetId + '/shift-utilizations-operations' + currentSearch)
                .then(async response => {
                    // JSON data parsed by response.json() call
                    if(response.ok ){
                    const data = await response.json();
                        if(data && data.data){
                            setStatusBarData(data.data.shift_states)
                            let uptimeString  = formatTime(data.data.runtime_shift)
                            let downtimeString  = formatTime(data.data.downtime_shift)
                            
                            setUptime(uptimeString)
                            setDowntime(downtimeString)
                            
                            let totalTime = data.data.runtime_shift + data.data.downtime_shift
                            let oee = (data.data.runtime_shift/totalTime)
                            // let oee = (data.data.runtime_shift/totalTime)*100
                            // let oeeString = 0 + '%'
                            // if(oee && oee.toFixed(0)){
                            //     oeeString = oee.toFixed(0) + '%'
                            // }
                            setOeeCalc(oee)
                            // setOeeString(oeeString)
                            setLoadingStatusBar(false)

                        }
                    }
                })
            }
        }catch (error){
            console.log(error.message)
        }

    }

    const getUtilizationOperation = () =>{
        try{
            if(selectedOrganization && assetId ){
                    
                    let currentSearch = location.search
                    getData(apiLink + '/v1/assets/'+ assetId + '/utilization-operation' + currentSearch)
                    .then(async response => {
                        // JSON data parsed by response.json() call
                        if(response.ok ){
                            const data = await response.json();
                            if(data && data.data){
                                setAssetHistoryActivity(data.data)
                                
                            }
                        }
                    })
            }
        }catch (error){
            console.log(error.message)
        }

    }

    const getActivityState = () => {
        try{
            if(assetId && selectedAsset && Object.keys(selectedAsset).length > 0){
                getData(apiLink + '/v1/assets/'+ assetId + '/activity-state')
                .then(async response => {
                    // JSON data parsed by response.json() call
                    if(response.ok ){
                    const data = await response.json();
                        if(data && data.data){
                            setAssetActivity(data.data)
                            let timePassed = 0
                            const lastOpDate = new Date(data.data.activity_finished_at)
                            const lastOpSecs = lastOpDate.getTime()
                    
                            const currenDate = new Date()
                            const currentSecs = currenDate.getTime()
                    
                            timePassed = (currentSecs - lastOpSecs)/1000
                            setTimePassed(timePassed)
                            setTimePassedDate(data.data.activity_finished_at)
                            
                            if(data.data.state ==='running' || timePassed <= 300){
                                setIsAssetRunning(true)
                            }else{
                                setIsAssetRunning(false)
                            }
                        }
                    }
                })
            }
        }catch (error){
            console.log(error.message)
        }
    }

    const formatTime =(seconds) => {
        let timeString = ''
        
        if(seconds){
            let totalMin = seconds/60
            let hours = totalMin/60
            hours = Math.floor(hours)
            let remMinutes = (totalMin - (hours*60))
            remMinutes = Math.floor(remMinutes)
            if(hours < 10 && hours >= 0){
                hours = '0' + hours 
            }
            if(remMinutes < 10 && remMinutes >= 0){
                remMinutes = '0' + remMinutes 
            }

            timeString = `${hours}:${remMinutes}h`



            // let formattedUptime  = moment.utc(seconds*1000).format('HH:mm')
            // let timeString = `${formattedUptime}h`
            // return timeString
        }else{
            timeString = `00:00h`
            
        }
        return timeString
    }


    const refreshActivityList = () => {

        let currentSearch = location.search
        getData(apiLink + '/v1/assets/'+ assetId + '/utilization-operation' + currentSearch)
        .then(async response => {
            // JSON data parsed by response.json() call
            if(response.ok ){
                const data = await response.json();
                if(data && data.data){
                    setAssetHistoryActivity(data.data)
                    
                }
            }
        })
        if(selectedOrganization && assetId){
            // calling this function to refresh instead of having an additional drill down 
            getShiftUtilizations()
            // update Pareto chart downtime categories
            setUpdateParetoDowntimeChart(true)
          }
    }

    const refreshDowntimeCategories = () => {
        try {
          if(selectedOrganization){
            // Get list of downtime categories 
            getData(apiLink + '/v1/organizations/' + selectedOrganization + '/downtime_categories')
            .then(async response => {
              if(response.ok ){
                const data = await response.json();
                if(data && data.data){
                  setDowntimeCategories(data.data)
                }
              }
            });
          }
          
        } catch (error) {
          console.log(error.message)
        }
  
      }

    function formatAMPM(date) {
        let event = new Date(date)
        let currentTime = event.toLocaleTimeString('en-US', { hour: '2-digit'})
        currentTime = currentTime.replace(/\s/g, '')
        if(currentTime[0] == '0'){
            currentTime = currentTime.substring(1)
        }

        return currentTime
    }

    const getSensorList = () => {
        getData(apiLink + '/v1/assets/'+ assetId  )
        .then(async response => {
            // JSON data parsed by response.json() call
            if(response.ok ){
            const data = await response.json();
                if(data && data.data){
                    setSelectedAsset(data.data[0])
                    setAssetOeeGoal(data.data[0].asset_oee_goal)
                }
            }
        })
    }

    const handleCloseEditSensorModal = () => {
        setOpenEditSensorModal(false)
    }

    const handleOpenEditSensorModal = () => {
        if(!isMobile){
            setOpenEditSensorModal(true)
        }
    }

    

    const calculateTotalFt = (totalRuntimeSeconds) => {
        if(totalRuntimeSeconds){
            let totalInches = totalRuntimeSeconds * 55
            let totalFeet = totalInches * 0.0833333
            if(totalFeet){
                totalFeet = totalFeet.toFixed(1)
            }

            return totalFeet

        }
    }

    const handleCloseAssetOeeGoalModal = () => {
        setOpenAssetOeeGoalModal(false)
    }

    return (
        <div className={`mt-2 ${isMobile ? 'max-h-[calc(100vh)]':'max-h-[calc(100vh-5rem)]'} overflow-scroll scrollbar-hide pb-10`}>
            {Object.keys(selectedAsset).length > 0 &&
            <div >
                <div className='ml-4 mr-4 py-2 sm:py-0'>
                    {/* Breadcrums */}
                    {isMobile ? (
                        <nav className='mb-2 flex items-center'>
                            <ol className='hidden sm:flex'>
                                <li className='breadcrumbsNav' onClick={() => navigate(`/${selectedOrganization}/assets?platform=mobile`)}>Assets</li>
                                <li className='breadcrumbsSpacer'>/</li>
                                <li className='breadcrumbsCurrent'>{selectedAsset.asset_custom_name  ? selectedAsset.asset_custom_name :selectedAsset.machine_name}</li>
                            </ol>
                            <ol className='flex sm:hidden'>
                                <li className='breadcrumbsNav flex text-lg items-center' onClick={() => navigate(`/${selectedOrganization}/assets?platform=mobile`)}>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                                        </svg>
                                    </div>
                                    <span>Assets</span>
                                </li>
                            </ol>
                        </nav>
                    ): (
                        <nav className='mb-2 flex items-center'>
                            <ol className='hidden sm:flex'>
                                <li className='breadcrumbsNav' onClick={() => navigate(`/${selectedOrganization}/assets`)}>Assets</li>
                                <li className='breadcrumbsSpacer'>/</li>
                                <li className='breadcrumbsCurrent'>{selectedAsset.asset_custom_name  ? selectedAsset.asset_custom_name :selectedAsset.machine_name}</li>
                            </ol>
                            <ol className='flex sm:hidden'>
                                <li className='breadcrumbsNav flex text-lg items-center' onClick={() => navigate(`/${selectedOrganization}/assets`)}>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                                        </svg>
                                    </div>
                                    <span>Assets</span>
                                </li>
                            </ol>
                        </nav>
                    )}
                    <div className='mb-2 flex items-center'>
                        <div className='flex mb-1 sm:mb-0 ml-0 sm:ml-2 '>
                            <ShiftFilterBtn select={'selected_asset'}/>
                        </div>
                        <div className='flex mb-1 sm:mb-0 ml-auto '>
                            <SetAssetNotificationsModal btnType='iconBtn' asset={selectedAsset} getOrgAssets={getOrgAssets} />
                        </div>
                        <div className='flex mb-1 sm:mb-0 ml-2 '>
                            <SetAssetTimeNotification asset={selectedAsset} getOrgAssets={getOrgAssets} />
                        </div>
                        <div className='hidden sm:flex mb-1 sm:mb-0 ml-2 '>
                            <AutoClassifyOperationsModal btnType='standardBtn' assetUuid={assetId} asset={selectedAsset}  />
                        </div>
                        <div className='hidden sm:flex mb-1 sm:mb-0 ml-2 '>
                            <EditAssetModal btnType='standardBtn' asset={selectedAsset} getOrgAssets={getOrgAssets} isUserAuthorized={isUserAuthorized} />
                        </div>
                        
                    </div>
                    <div className='mt-1 mb-2 py-2 flex sm:hidden'>
                        <div className='flex '>
                            <EditAssetModal btnType='standardBtn' asset={selectedAsset} getOrgAssets={getOrgAssets} isUserAuthorized={isUserAuthorized} />
                        </div>
                        <div className='flex ml-auto '>
                            <AutoClassifyOperationsModal btnType='standardBtn' assetUuid={assetId} asset={selectedAsset}  />
                        </div>
                    </div>
                    <div className='flex w-full max-w-full border-b-[1px] border-gray-200 dark:border-gray-700 pb-10'>
                        <div className='z-10'>
                            <div className={`border-8 md:border-[14px] ${isAssetRunning? 'border-green-600':'border-red-600'} rounded-full bg-white`}>
                                <OptimizeLargeImage alt={selectedAsset.machine_name} source={selectedAsset.asset_custom_picture_url  ? selectedAsset.asset_custom_picture_url : selectedAsset.machine_picture_url} size='2xl' />
                            </div>
                        </div>
                        <div className='flex flex-col grow '>
                            <div className='flex md:h-[148px]'>
                                <div className='flex flex-col w-[200px] sm:w-[250px] lg:w-[285px] xl:w-[450px] justify-end' >
                                    <div className='ml-4 pl-4 border-l-2 border-gray-200 dark:border-gray-700 '>
                                        <div className='leading-6 md:leading-7 truncate text-lg md:text-2xl text-gray-600 dark:text-gray-200 font-normal'>{selectedAsset.asset_custom_name  ? selectedAsset.asset_custom_name :selectedAsset.machine_name}</div>
                                        <div className='leading-5 md:leading-6 truncate text-lg md:text-xl text-gray-400 dark:text-gray-400 font-medium'>{selectedAsset.asset_custom_identifier  ? selectedAsset.asset_custom_identifier :selectedAsset.asset_uuid}</div>
                                        <div className='truncate text-sm md:text-base text-gray-400 dark:text-gray-400'>{selectedAsset.department_name}</div>
                                    </div>
                                    <div className='h-8 md:h-10 mt-1 w-full ml-[-60px] text-right '>
                                        {assetActivity && Object.keys(assetActivity).length > 0 &&
                                            setLastOpTime(selectedAsset, assetActivity, timePassed, timePassedDate)
                                        } 
                                    </div>
                                </div>
                                {/* New Sensor Card********** */}
                                <div className='hidden sm:flex ml-auto lg:mr-8 w-[65px] items-center'  >
                                    <AnimatePresence mode='wait'>
                                        <button className='flex relative' onClick={handleOpenEditSensorModal}>
                                            {isRunningRealTime === null && 
                                                <motion.div 
                                                    key='SenseAiLightGrayContainer'
                                                    initial={{ y: 10, opacity: 0 }}
                                                    animate={{ y: 0, opacity: 1 }}
                                                    exit={{ y: -10, opacity: 0 }}
                                                    transition={{ duration: 0.2 }}
                                                >
                                                    <img src={SenseAiLightGray} alt={selectedAsset.node_name} loading={'lazy'} className='h-28' />
                                                </motion.div>
                                            } 
                                            {isRunningRealTime === false && 
                                                <motion.div 
                                                    key='SenseAiCyanContainer'
                                                    initial={{ y: 10, opacity: 0 }}
                                                    animate={{ y: 0, opacity: 1 }}
                                                    exit={{ y: -10, opacity: 0 }}
                                                    transition={{ duration: 0.2 }}
                                                >
                                                    <img src={SenseAiCyan} alt={selectedAsset.node_name} loading={'lazy'} className='h-28' />
                                                </motion.div>
                                                }
                                            {isRunningRealTime === true && 
                                                <motion.div 
                                                    key='SenseAiOrangeContainer'
                                                    initial={{ y: 10, opacity: 0 }}
                                                    animate={{ y: 0, opacity: 1 }}
                                                    exit={{ y: -10, opacity: 0 }}
                                                    transition={{ duration: 0.2 }}
                                                >
                                                    <img src={SenseAiOrange} alt={selectedAsset.node_name} loading={'lazy'} className='h-28' />
                                                </motion.div>
                                            }
                                            <div className='absolute top-6 right-0'>
                                                        <div className='mt-1'>
                                                            {selectedAsset.node_is_online ? (
                                                                <div className="relative">
                                                                    <div className="w-4 h-4 bg-transparent"></div>
                                                                    <div className="absolute top-1 right-0 -mr-1 -mt-1 w-4 h-4 rounded-full bg-green-400 dark:bg-green-600 animate-ping"></div>
                                                                    <div className="absolute top-1 right-0 -mr-1 -mt-1 w-4 h-4 rounded-full bg-green-400 dark:bg-green-600"></div>
                                                                </div>
                                                            ):(
                                                                <div className="relative">
                                                                    <div className="w-4 h-4 bg-transparent"></div>
                                                                    <div className="absolute top-1 right-0 -mr-1 -mt-1 w-4 h-4 rounded-full bg-gray-400 dark:bg-gray-600"></div>
                                                                    <div className="absolute top-1 right-0 -mr-1 -mt-1 w-4 h-4 rounded-full bg-gray-400 dark:bg-gray-600"></div>
                                                                </div>
                                                            )}
                                                        </div>
                                            </div>
                                            <div className='absolute top-6 left-0'>
                                                {handleWifiIcon(selectedAsset, isDark)}
                                            </div>
                                        </button>

                                    </AnimatePresence>
                                </div>
                                <div className='hidden lg:flex items-center mb-[-23px]'>
                                    <div className='flex flex-col items-center'>
                                        <div className='font-medium text-4xl text-green-600 ml-auto mr-2 py-1'>{uptime}</div>
                                        <div className='font-medium text-4xl text-red-600 ml-auto mr-2 py-1'>{downtime}</div>
                                    </div>
                                    <div className='mr-[-15px]'>
                                        <motion.button
                                            whileHover={{ scale: 1.05 }}
                                            whileTap={{ scale: 0.98 }} 
                                            onClick={() => setOpenAssetOeeGoalModal(true)}
                                        >
                                            {/* <ProgressGaugeLarge stringValue={oeeString} unit={'OEE'} numericValue={oeeCalc} thresholdValue={assetOeeGoal} /> */}
                                            <OeeGaugeLarge oeeValue={oeeCalc} unit={'OEE'} goal={assetOeeGoal} />
                                        </motion.button>
                                    </div>
                                    {/* TESTINGGGGGG ************************* */}
                                    {/* <div className='mr-[-15px]'>
                                        <motion.button
                                            whileHover={{ scale: 1.05 }}
                                            whileTap={{ scale: 0.98 }} 
                                            onClick={() => setOpenAssetOeeGoalModal(true)}
                                        >
                                            <ProgressGaugeLarge stringValue={totalOpsKpis.string_value} unit={'made'} currentCount={totalOpsKpis.numeric_value} numericValue={totalOpsKpis.numeric_value} thresholdValue={totalOpsKpis.threshold_value}/>
                                        </motion.button>
                                    </div> */}
                                    {/* TESTINGGGGGG ************************* */}
                                </div>
                            </div>
                            <div className='grow pt-[18px] ml-[-17px] rounded-full '>
                                <StatusBarLarge selectedOrganization={selectedOrganization} assetId={assetId} statusBarData={statusBarData} barChartCategories={barChartCategoriesAll} refreshActivityList={refreshActivityList} operationsList={operationsList} refreshDowntimeCategories={refreshDowntimeCategories} downtimeCategories={downtimeCategories} />
                            </div>
                        </div>
                    </div>
                    <div className='flex sm:hidden border-b-[1px] border-gray-200 dark:border-gray-700 items-center mx-auto justify-center my-4 xl:my-2 xl:justify-end pb-2'  >
                        <AnimatePresence mode='wait'>
                            <button className='flex' onClick={handleOpenEditSensorModal}>
                                {isRunningRealTime === null && 
                                    <motion.div 
                                        key='SenseAiLightGrayContainer2'
                                        initial={{ y: 10, opacity: 0 }}
                                        animate={{ y: 0, opacity: 1 }}
                                        exit={{ y: -10, opacity: 0 }}
                                        transition={{ duration: 0.2 }}
                                    >
                                        <img src={SenseAiLightGray} alt={selectedAsset.node_name} loading={'lazy'} className='h-24' />
                                    </motion.div>
                                } 
                                {isRunningRealTime === false && 
                                    <motion.div 
                                        key='SenseAiCyanContainer2'
                                        initial={{ y: 10, opacity: 0 }}
                                        animate={{ y: 0, opacity: 1 }}
                                        exit={{ y: -10, opacity: 0 }}
                                        transition={{ duration: 0.2 }}
                                    >
                                        <img src={SenseAiCyan} alt={selectedAsset.node_name} loading={'lazy'} className='h-24' />
                                    </motion.div>
                                    }
                                {isRunningRealTime === true && 
                                    <motion.div 
                                        key='SenseAiOrangeContainer2'
                                        initial={{ y: 10, opacity: 0 }}
                                        animate={{ y: 0, opacity: 1 }}
                                        exit={{ y: -10, opacity: 0 }}
                                        transition={{ duration: 0.2 }}
                                    >
                                        <img src={SenseAiOrange} alt={selectedAsset.node_name} loading={'lazy'} className='h-24' />
                                    </motion.div>
                                }
                                <div className='flex flex-col justify-center pl-2 max-w-[200px] px-4  xl:max-w-[175px] 2xl:max-w-[200px]'>
                                    <div className='text-left leading-6 truncate text-lg text-gray-600 dark:text-white font-medium'>{selectedAsset.node_name}</div>
                                    <div className='text-left leading-6 truncate text-base text-gray-500 dark:text-gray-400 font-normal'>{selectedAsset.node_uuid}</div>
                                    <div className='flex items-center justify-between px-3 py-1'>
                                        <div>
                                            {/* <WifiIcon className='w-5 h-5 text-gray-400 dark:text-gray-400' /> */}
                                            {handleWifiIcon(selectedAsset, isDark)}
                                        </div>
                                        <div>
                                            {selectedAsset.node_is_online ? (
                                                <div className="relative">
                                                    <div className="w-4 h-4 bg-transparent"></div>
                                                    <div className="absolute top-1 right-0 -mr-1 -mt-1 w-4 h-4 rounded-full bg-green-400 dark:bg-green-600 animate-ping"></div>
                                                    <div className="absolute top-1 right-0 -mr-1 -mt-1 w-4 h-4 rounded-full bg-green-400 dark:bg-green-600"></div>
                                                </div>
                                            ):(
                                                <div className="relative">
                                                    <div className="w-4 h-4 bg-transparent"></div>
                                                    <div className="absolute top-1 right-0 -mr-1 -mt-1 w-4 h-4 rounded-full bg-gray-400 dark:bg-gray-600"></div>
                                                    <div className="absolute top-1 right-0 -mr-1 -mt-1 w-4 h-4 rounded-full bg-gray-400 dark:bg-gray-600"></div>
                                                </div>
                                            )}
                                        </div>

                                    </div>
                                </div>
                            </button>
                        </AnimatePresence>
                        
                    </div>
                    <div className='flex lg:hidden grow mb-10 mt-4 w-full border-b-[1px] border-gray-200 dark:border-gray-700 '>
                    <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.98 }} 
                        onClick={() => setOpenAssetOeeGoalModal(true)}
                    >
                        <div className='grid grid-cols-2 gap-2 w-full'>
                                <div className='mr-[-15px] flex justify-center'>
                                {/* <ProgressGaugeLarge stringValue={oeeString} unit={'OEE'} numericValue={oeeCalc} thresholdValue={assetOeeGoal}/> */}
                                    <OeeGaugeLarge oeeValue={oeeCalc} unit={'OEE'} goal={assetOeeGoal} />
                                </div>
                            <div className='flex flex-col justify-center w-full items-center lg:hidden '>
                                <div className='font-medium text-4xl text-green-600 mx-auto mb-2'>{uptime}</div>
                                <div className='font-medium text-4xl text-red-600 mx-auto'>{downtime}</div>
                            </div>
                        </div>
                    </motion.button>
                    </div>
                    {loadingStatusBar &&
                        <div className='rounded-full'>
                            <LinearProgress color="primary" sx={{height:2}}/>
                        </div>
                        }
                    <div className='flex flex-col-reverse sm:flex-row w-full mt-3'>
                        <nav>
                            <div className='flex overflow-x-scroll scrollbar-hide space-x-2 md:space-x-3 border-b border-blue-600 w-fit'>
                            {subNav.map((item) => (
                                <div
                                key={item.url}
                                className='relative pt-0.5 pb-1.5'
                                onClick={() => setSelectedTab(item)}
                                >
                                <span className={`${item.url === selectedTab.url ? 'font-medium':' font-base'} mr-1 pb-1 text-gray-600 dark:text-gray-100  hover:text-gray-900 dark:hover:text-gray-300  outline-none text-base cursor-pointer bg-transparent whitespace-nowrap`}>{`${item.name}`}</span>
                                {item === selectedTab ? (
                                    <motion.div className="underline" layoutId="underline" />
                                ) : null}
                                </div>
                            ))}
                            </div>
                        </nav>
                        
                    </div>
                </div>
                {selectedSubNav &&
                    subLinkPage(selectedSubNav, selectedAsset, getSensorList,  assetHistoryActivity, refreshActivityList, handleToggleMqtt, operationsList, refreshDowntimeCategories, downtimeCategories,isMobile, updateParetoDowntimeChart, setUpdateParetoDowntimeChart, assetOeeGoal)
                }
            </div>
            }
            {openEditSensorModal && 
                <EditSensorModal btnType='hideBtn' sensor={selectedAsset} getSensorList={getSensorList} remoteOpen={openEditSensorModal} handleCloseModal={handleCloseEditSensorModal}/>
            }
            {openAssetOeeGoalModal &&
            <OeeAssetGoalModal remoteOpenModal={openAssetOeeGoalModal} handleCloseModal={handleCloseAssetOeeGoalModal} selectedAsset={selectedAsset} setAssetOeeGoal={setAssetOeeGoal} setSelectedAsset={setSelectedAsset} />
            }
        </div>
    )
}

export default SelectedAssetPage

const setLastOpTime = (asset, assetActivity, timePassed, timePassedDate) => {
    if(asset && Object.keys(asset).length > 0){
       
    
        if(assetActivity.state === 'running'){
            return (
                <div className='text-white bg-green-600 font-medium text-sm md:text-lg leading-5 md:leading-6 pr-2 py-1 md:py-2 min-w-[240px] md:min-w-[310px] rounded-tl-full rounded-r-full'>
                    Running
                </div>
            )
        }else if(assetActivity.state === 'stopped'){
            if(timePassed <= 300){
                return (
                    <div className='text-white bg-green-600 font-medium text-sm md:text-lg leading-5 md:leading-6 pr-2 py-1 md:py-2 min-w-[240px] md:min-w-[310px] rounded-tl-full rounded-r-full'>
                        Last Op:&nbsp;<TimeAgo datetime={timePassedDate} />
                    </div>
                )
            }else{
                return (
                    <div className='text-white bg-red-600 font-medium text-sm md:text-lg leading-5 md:leading-6 pr-2 py-1 md:py-2 min-w-[240px] md:min-w-[310px] rounded-tl-full rounded-r-full'>
                         Last Op:&nbsp;<TimeAgo datetime={timePassedDate} /> 
                     </div>
                )

            }
        }

    }

}


const subLinkPage = (link, selectedAsset, getSensorList, assetHistoryActivity, refreshActivityList, handleToggleMqtt, operationsList, refreshDowntimeCategories, downtimeCategories, isMobile,updateParetoDowntimeChart, setUpdateParetoDowntimeChart, assetOeeGoal) => {
    const allLinks = {
        overview: { link: "overview", content: <SelectedAssetPageOverviewTab selectedAsset={selectedAsset} getSensorList={getSensorList} assetHistoryActivity={assetHistoryActivity} refreshActivityList={refreshActivityList} handleToggleMqtt={handleToggleMqtt} operationsList={operationsList} refreshDowntimeCategories={refreshDowntimeCategories} downtimeCategories={downtimeCategories} isMobile={isMobile} updateParetoDowntimeChart={updateParetoDowntimeChart} setUpdateParetoDowntimeChart={setUpdateParetoDowntimeChart} assetOeeGoal={assetOeeGoal} /> },
        health: { link: "health", content: <SelectedAssetPageHealthTab selectedAsset={selectedAsset} /> },
        // history: { link: "history", content: <SelectedAssetPageHistoryTab /> },
        // insights: { link: "insights", content: <div className='text-[128px]'>🧀 🥬</div> },
    };

      return (
        <AnimatePresence mode='wait'>
        <motion.div
        key={allLinks[link].link}
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -10, opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          {allLinks[link].content}
        </motion.div>
      </AnimatePresence>
    )           
}


function handleWifiIcon(sensor, isDark) {
    if(sensor.node_is_online){
      if(sensor && sensor.node_vitals && sensor.node_vitals.wifi_rssi){
        
        let rssi = parseInt(sensor.node_vitals.wifi_rssi)
        
        if(rssi > -50){
            return (
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className={`w-6 h-6 ${isDark? 'text-gray-400' : 'text-gray-500'}`} viewBox="0 0 16 16">
                    <path d="M15.384 6.115a.485.485 0 0 0-.047-.736A12.444 12.444 0 0 0 8 3C5.259 3 2.723 3.882.663 5.379a.485.485 0 0 0-.048.736.518.518 0 0 0 .668.05A11.448 11.448 0 0 1 8 4c2.507 0 4.827.802 6.716 2.164.205.148.49.13.668-.049z"/>
                    <path d="M13.229 8.271a.482.482 0 0 0-.063-.745A9.455 9.455 0 0 0 8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065A8.46 8.46 0 0 1 8 7a8.46 8.46 0 0 1 4.576 1.336c.206.132.48.108.653-.065zm-2.183 2.183c.226-.226.185-.605-.1-.75A6.473 6.473 0 0 0 8 9c-1.06 0-2.062.254-2.946.704-.285.145-.326.524-.1.75l.015.015c.16.16.407.19.611.09A5.478 5.478 0 0 1 8 10c.868 0 1.69.201 2.42.56.203.1.45.07.61-.091l.016-.015zM9.06 12.44c.196-.196.198-.52-.04-.66A1.99 1.99 0 0 0 8 11.5a1.99 1.99 0 0 0-1.02.28c-.238.14-.236.464-.04.66l.706.706a.5.5 0 0 0 .707 0l.707-.707z"/>
                </svg>
            )
        }else if(rssi > -60 && rssi <= -50){
            return(
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className={`w-6 h-6 ${isDark? 'text-gray-400' : 'text-gray-500'}`} viewBox="0 0 16 16">
                    <path d="M13.229 8.271c.216-.216.194-.578-.063-.745A9.456 9.456 0 0 0 8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065A8.46 8.46 0 0 1 8 7a8.46 8.46 0 0 1 4.577 1.336c.205.132.48.108.652-.065zm-2.183 2.183c.226-.226.185-.605-.1-.75A6.473 6.473 0 0 0 8 9c-1.06 0-2.062.254-2.946.704-.285.145-.326.524-.1.75l.015.015c.16.16.408.19.611.09A5.478 5.478 0 0 1 8 10c.868 0 1.69.201 2.42.56.203.1.45.07.611-.091l.015-.015zM9.06 12.44c.196-.196.198-.52-.04-.66A1.99 1.99 0 0 0 8 11.5a1.99 1.99 0 0 0-1.02.28c-.238.14-.236.464-.04.66l.706.706a.5.5 0 0 0 .708 0l.707-.707z"/>
                </svg>
            )
        }else if(rssi <= -60){
            return(
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className={`w-6 h-6 ${isDark? 'text-gray-400' : 'text-gray-500'}`} viewBox="0 0 16 16">
                    <path d="M11.046 10.454c.226-.226.185-.605-.1-.75A6.473 6.473 0 0 0 8 9c-1.06 0-2.062.254-2.946.704-.285.145-.326.524-.1.75l.015.015c.16.16.407.19.611.09A5.478 5.478 0 0 1 8 10c.868 0 1.69.201 2.42.56.203.1.45.07.611-.091l.015-.015zM9.06 12.44c.196-.196.198-.52-.04-.66A1.99 1.99 0 0 0 8 11.5a1.99 1.99 0 0 0-1.02.28c-.238.14-.236.464-.04.66l.706.706a.5.5 0 0 0 .707 0l.708-.707z"/>
                </svg>
            )
        }
      }else{
        return (
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className={`w-5 h-5 ${isDark? 'text-gray-400' : 'text-gray-500'}`} viewBox="0 0 16 16">
                <path d="M10.706 3.294A12.545 12.545 0 0 0 8 3C5.259 3 2.723 3.882.663 5.379a.485.485 0 0 0-.048.736.518.518 0 0 0 .668.05A11.448 11.448 0 0 1 8 4c.63 0 1.249.05 1.852.148l.854-.854zM8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065 8.448 8.448 0 0 1 3.51-1.27L8 6zm2.596 1.404.785-.785c.63.24 1.227.545 1.785.907a.482.482 0 0 1 .063.745.525.525 0 0 1-.652.065 8.462 8.462 0 0 0-1.98-.932zM8 10l.933-.933a6.455 6.455 0 0 1 2.013.637c.285.145.326.524.1.75l-.015.015a.532.532 0 0 1-.611.09A5.478 5.478 0 0 0 8 10zm4.905-4.905.747-.747c.59.3 1.153.645 1.685 1.03a.485.485 0 0 1 .047.737.518.518 0 0 1-.668.05 11.493 11.493 0 0 0-1.811-1.07zM9.02 11.78c.238.14.236.464.04.66l-.707.706a.5.5 0 0 1-.707 0l-.707-.707c-.195-.195-.197-.518.04-.66A1.99 1.99 0 0 1 8 11.5c.374 0 .723.102 1.021.28zm4.355-9.905a.53.53 0 0 1 .75.75l-10.75 10.75a.53.53 0 0 1-.75-.75l10.75-10.75z"/>
            </svg>
        )
      }
    }else{
      return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className={`w-5 h-5 ${isDark? 'text-gray-400' : 'text-gray-500'}`} viewBox="0 0 16 16">
            <path d="M10.706 3.294A12.545 12.545 0 0 0 8 3C5.259 3 2.723 3.882.663 5.379a.485.485 0 0 0-.048.736.518.518 0 0 0 .668.05A11.448 11.448 0 0 1 8 4c.63 0 1.249.05 1.852.148l.854-.854zM8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065 8.448 8.448 0 0 1 3.51-1.27L8 6zm2.596 1.404.785-.785c.63.24 1.227.545 1.785.907a.482.482 0 0 1 .063.745.525.525 0 0 1-.652.065 8.462 8.462 0 0 0-1.98-.932zM8 10l.933-.933a6.455 6.455 0 0 1 2.013.637c.285.145.326.524.1.75l-.015.015a.532.532 0 0 1-.611.09A5.478 5.478 0 0 0 8 10zm4.905-4.905.747-.747c.59.3 1.153.645 1.685 1.03a.485.485 0 0 1 .047.737.518.518 0 0 1-.668.05 11.493 11.493 0 0 0-1.811-1.07zM9.02 11.78c.238.14.236.464.04.66l-.707.706a.5.5 0 0 1-.707 0l-.707-.707c-.195-.195-.197-.518.04-.66A1.99 1.99 0 0 1 8 11.5c.374 0 .723.102 1.021.28zm4.355-9.905a.53.53 0 0 1 .75.75l-10.75 10.75a.53.53 0 0 1-.75-.75l10.75-10.75z"/>
        </svg>
      )
    }

}
