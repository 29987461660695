import React, { useState, useRef, useEffect } from 'react';
import { motion } from "framer-motion";
import { darkModeState } from '../../atoms/darkModeState';
import { useRecoilValue } from 'recoil';
import { getData } from '../../ApiCalls/DataApis';
import { apiLink } from '../../ApiCalls/ApisVariables';
import { globalSelectedOrganization } from '../../atoms/orgStates';
import MqttService from '../../core/services/MqttService';
import { createMessagingMQTT } from '../../core/services/MqttMessaging';
import { removeMessage, updateOrInsertMessageByDate } from '../../utils/MessageDataSorting';
import { toast } from 'aws-amplify';

function RecordVoiceButton({chatInputMessageText, setAudioURL, audioURL, userInfo, messages, setMessages, chatInfo}) {
    const selectedOrganization = useRecoilValue(globalSelectedOrganization)
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [audioBlob, setAudioBlob] = useState(null);
  const audioRef = useRef(null);
  const [stream, setStream] = useState(null);
  const isDark = useRecoilValue(darkModeState);



  const handleMouseDown = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);
      
      recorder.ondataavailable = (event) => {

        let audio_data = audioChunks.push(event.data)
        setAudioChunks({...audio_data});
      };
      
      setStream(stream);
      recorder.start();
      setMediaRecorder(recorder);
      setIsRecording(true);

    } catch (error) {
      console.error('Error accessing the microphone', error);
    }
  };

  const handleMouseUp = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      mediaRecorder.onstop = () => {
        const audioBlob = new Blob(audioChunks, { type: 'audio/webm' });
        const audioUrl = URL.createObjectURL(audioBlob);
        setAudioBlob(audioBlob)
        setAudioURL(audioUrl);
        setAudioChunks([]);
        setIsRecording(false);
        setMediaRecorder(null)
        // Clean up the media stream
        if (stream) {
          stream.getTracks().forEach((track) => track.stop());
          setStream(null);

        }
      };
    }
  }

  const cancelVoiceMessage = () => {
    setAudioURL('')
    setAudioChunks([])
    setMediaRecorder(null)
    setIsRecording(false)
  }

  const sendVoice = async (event) => {

    try{
        let url = ''
        let file = null
        if(audioBlob){
            let fileName = `audio_${Date.now()}.webm`
            file = audioBlob
            file.name = fileName
        }
        

        

        if(file){
            url = `?file_name=${file.name}&file_size=${file.size}&file_type=${file.type}&file_is_compressed=false&file_is_voice=true`
        }
        
        
        getData(apiLink + '/v1/organizations/' + selectedOrganization + `/file_upload_request` + url)
        .then(async response => {
            if(response.ok ){
            const data = await response.json();

            if(data){
                

                let url = data.data.signed_url
                let file_uuid = data.data.file_uuid

                    let currentTime = new Date().toISOString();
                    let message_uuid = `msg_${userInfo.user_username}_${Date.now()}`

                    let constructedMessage = {
                        "message_uuid": message_uuid,
                        "message_created_at": currentTime,
                        "message_text": chatInputMessageText,
                        "message_file_uuid": file_uuid,
                        'user_first_name':userInfo.user_first_name,
                        'user_username':userInfo.user_username,
                        'isFileUploading': true,
                        'file_name':file.name,
                        'file_size':file.size,
                        'file_is_voice': true,
                        percent: 0,
                        xhr: new XMLHttpRequest()
                    }
                    constructedMessage.xhr.open('PUT', url, true);
                    constructedMessage.xhr.setRequestHeader('Content-Type', file.type);

                    const updatedMessages = updateOrInsertMessageByDate(messages, constructedMessage)
                    setMessages({...updatedMessages})
            
                    constructedMessage.xhr.upload.onprogress = (event) => {
                    if (event.lengthComputable) {
                        const percentComplete = (event.loaded / event.total) * 100;
                        // console.log('percentComplete', percentComplete)
                        
                        
                          setMessages((prevMessages) => ({
                            ...prevMessages,
                            [message_uuid]: { ...prevMessages[message_uuid], progress: percentComplete }
                          }));
                        // setUploadProgress(percentComplete);
                    }
                    };
                    cancelVoiceMessage()
            
                    constructedMessage.xhr.onload = () => {
                    if (constructedMessage.xhr.status === 200) {

                        // POST MQTT MESSAGE
                        if (MqttService.isConnected) {
                            let msg = {
                                from_username: userInfo.user_username,
                                to_chatroom_uuid: chatInfo.chatroom_uuid,
                                action: 'post_message',
                                message_text: constructedMessage.message_text,
                                message_file_uuid: constructedMessage.message_file_uuid
                            }
                            msg = createMessagingMQTT(msg);
                            // console.log('msg', msg)
                            if(msg.topic && msg.payload)
                            {
                                MqttService.publishMessage(msg.topic, JSON.stringify(msg.payload));
                            }
                        }else{
                            toast.error('Waiting for Network')
                        }

                        const updatedMessages = removeMessage(messages, constructedMessage)
                        setMessages({...updatedMessages})
                        // toast.success('File uploaded successfully!');
                        // setUploadProgress(0); // Reset progress after upload
                        // setFile(null); // Reset file input after upload
                        // closeModal()

                    } else {
                        toast.error('Failed to upload file.');
                    }
                    };
            
                    constructedMessage.xhr.onerror = () => {
                        toast.error('An error occurred while uploading the file.');
                    };
            
                    constructedMessage.xhr.send(file);

                    // setTimeout(() =>{
                    //     constructedMessage.xhr.abort()
                    //     toast.error('Message Aborted')
                    // },500)


            }
            }
        })

    }catch (error) {
        console.error('Error uploading file:', error);
        toast.error('Failed to upload file.');
    }

    
}

  return (
    <motion.div
        key='voiceButton'
        animate={{
            x: 0,
            y: 0,
            scale: chatInputMessageText ? 0 : 1,
            rotate: chatInputMessageText ? 0 : 0,
        }}
        className={`flex items-center ${audioURL && 'w-full' } `}
    >
        {audioURL ? (
            <motion.button 
            whileHover={{ scale:1.05 }} 
            whileTap={{ scale: 0.95  }}  
             onClick={cancelVoiceMessage}
            className={`text-gray-600 dark:text-white p-1 dark:bg-slate-600 rounded-full mr-2`}
            
        >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
            </svg>

        </motion.button>

        ):(
            <button 
                type='button'
                disabled={chatInputMessageText}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onTouchStart={handleMouseDown} // For mobile support
                onTouchEnd={handleMouseUp} // For mobile support
                className={`record-button ${isRecording ? 'recording' : ''} text-gray-600 dark:text-gray-400 disabled:hidden`}
                
            >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 18.75a6 6 0 0 0 6-6v-1.5m-6 7.5a6 6 0 0 1-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 0 1-3-3V4.5a3 3 0 1 1 6 0v8.25a3 3 0 0 1-3 3Z" />
                </svg>
            </button>
        )}
            {audioURL && (
                    <audio style={{ width: '100%', height:'40px', backgroundColor:'transparent', color:'transparent' }} ref={audioRef} controls src={audioURL}></audio>

            )}
            {audioURL && (
                  <button 
                    onClick={(e) => sendVoice(e, 'submitClick')}
                    className='text-blue-500 p-1'
                >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                        <path d="M3.478 2.404a.75.75 0 0 0-.926.941l2.432 7.905H13.5a.75.75 0 0 1 0 1.5H4.984l-2.432 7.905a.75.75 0 0 0 .926.94 60.519 60.519 0 0 0 18.445-8.986.75.75 0 0 0 0-1.218A60.517 60.517 0 0 0 3.478 2.404Z" />
                    </svg>
                </button>
            )}

         <style jsx='true'>{`
         audio::-webkit-media-controls-panel {
            background-color: ${isDark ? '#334155' : '#cbd5e1'};
          }
        audio::-webkit-media-controls-play-button {
            background-color: ${isDark ? '#64748b' : '#e2e8f0'};
            border-radius: 50%;
            color: #fff; /* Change this to your desired color */
        }
        audio::-webkit-media-controls-timeline {
            background-color:${isDark ? '#64748b' : '#e2e8f0'};
            border-radius: 25px;
            margin-left:4px;
        }
        audio::-webkit-media-controls-current-time-display {
            color: ${isDark ? '#94a3b8' : '#475569'};
        }
        audio::-webkit-media-controls-volume-slider {
            background-color: ${isDark ? '#64748b' : '#e2e8f0'};
            margin-left:2px;
            border-radius: 25px;
            padding-left: 8px;
            padding-right: 8px;
        }
        .record-button {
          background-color: ${isRecording ? 'red' : 'transparent'};
          padding: 0px 0px;
          border: none;
          border-radius: 50%;
          cursor: pointer;
          transition: background-color 0.3s;
        }
        .record-button.recording {
          animation: pulse 1s infinite;
          color: white;
        }
        @keyframes pulse {
          0% {
            transform: scale(1);
          }
          50% {
            transform: scale(1.1);
          }
          100% {
            transform: scale(1);
          }
        }
      `}</style>
    </motion.div>
  )
}

export default RecordVoiceButton