import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { motion } from 'framer-motion';
import MqttService from '../../../core/services/MqttService';
import { Avatar, AvatarGroup } from '@mui/material';
import { formatDateToAmPm } from '../../../utils/DateFormating';
import ProgressCircle from '../../../utils/ProgressCircle';
import { darkModeState } from '../../../atoms/darkModeState';
import { useRecoilValue } from 'recoil';
import { createMessagingMQTT } from '../../../core/services/MqttMessaging';
import toast from 'react-hot-toast';
import { removeMessage } from '../../../utils/MessageDataSorting';
import ReceiverCompressedImageInMessageComponent from './ReceiverCompressedImageInMessageComponent';
import ReceiverEmojisOnlyMessageComponent from './ReceiverEmojisOnlyMessageComponent';
import ReceiverMessageOnlyComponent from './ReceiverMessageOnlyComponent';
import ReceiverFileMessageComponent from './ReceiverFileMessageComponent';
import ReceiverVoiceMessageComponent from './ReceiverVoiceMessageComponent';
import { useSearchParams } from 'react-router-dom';
import { triggerVibration } from '../../../utils/messagingUtils';

function ReceiverMessageComponent({message, userInfo, contextMenu, styles, participants, showImage, onRightClick, handleTouchClick, isGroup, showName, scrollToMessage, emojiTextSize, messages, setMessages, handleDownloadFile, isLoadingFile, updateEmoji, shouldRoundTopCorner,shouldRoundBottomCorner}) {
  const isDark = useRecoilValue(darkModeState);
  const [messageStatus, setMessageStatus] = useState('loading'); // Initial status is loading
  const [searchParams, setSearchParams] = useSearchParams()
  const [isMobile, setIsMobile] = useState(false)
  const longPressTimer = useRef(null);
  const longPressDuration = 200; // milliseconds
  const tapTimer = useRef(null);
  const tapCount = useRef(0);
  const doubleTapDuration = 300; // milliseconds
    

    useEffect(() => {

      let currentIsMobile = searchParams.get('platform')
      if(currentIsMobile === 'mobile'){
          setIsMobile(true)
      }else{
          setIsMobile(false)
      }

  },[])

  useEffect(() => {
    if(message && !message.file_uuid && message.message_is_only_emoji_count && message.message_is_only_emoji_count > 0){
      setMessageStatus('only_emojis')
    }else if(message && message.file_uuid && message.file_is_compressed){
        setMessageStatus('message_is_uncompressed_image')
    }else if(message && message.file_uuid && message.file_is_voice ){
      setMessageStatus('message_is_voice')
    }else if(message && message.file_uuid && !message.isFileUploading  && !message.file_is_compressed){
      setMessageStatus('message_is_file')
    }else if(message && !message.file_uuid && message.message_text && message.message_text.length > 0){
        setMessageStatus('only_message')
    }

  },[message])


const handleTailCss = (emojiCount) => {
    if(emojiCount && emojiCount > 0){
        return{
            color:'transparent'
        }
    }else {
      return{
          color: isDark ? '#1e293b':'#f1f5f9',
      }
    }
}




const handleTouchStart = useCallback((e) => {
  if (e.touches.length > 1) return; // Ignore multi-touch

  // Long press logic
  longPressTimer.current = setTimeout(() => {
    triggerVibration();
    handleTouchClick(e, message,message.user_username, userInfo.user_username )
    tapCount.current = 0; // Reset tap count on long press
  }, longPressDuration);

  // Double tap logic
  tapCount.current += 1;
  if (tapCount.current === 1) {
    tapTimer.current = setTimeout(() => {
      tapCount.current = 0;
    }, doubleTapDuration);
  } else if (tapCount.current === 2) {
    clearTimeout(tapTimer.current);
    handleDoubleTap(message);
    tapCount.current = 0;
  }
}, [ message]);

const handleDoubleTap = (message) => {

  try{
    if (MqttService.isConnected) {

      let msg = {
          from_username: userInfo.user_username,
          action: 'update_emoji_interaction',
          message_uuid: message.message_uuid,
          emoji_codepoints: "1F44D"
      }
      
      msg = createMessagingMQTT(msg);

      if(msg.topic && msg.payload){
          MqttService.publishMessage(msg.topic, JSON.stringify(msg.payload));
      }
    }
  }catch (e){
    toast.error(e)
  }

};

const handleTouchEnd = useCallback(() => {
  if (longPressTimer.current) {
    clearTimeout(longPressTimer.current);
  }
}, []);



const renderMessage = () => {
    switch (messageStatus) {
      case 'loading':
        return <div/>;
      case 'message_is_uncompressed_image':
        return <ReceiverCompressedImageInMessageComponent isMobile={isMobile} message={message} scrollToMessage={scrollToMessage} isGroup={isGroup} showImage={showImage}  handleTailCss={handleTailCss} participants={participants} userInfo={userInfo}  updateEmoji={updateEmoji} showName={showName} handleDownloadFile={handleDownloadFile}/>;
      case 'message_is_file':
        return <ReceiverFileMessageComponent isMobile={isMobile} message={message} scrollToMessage={scrollToMessage} isGroup={isGroup} showImage={showImage}  handleTailCss={handleTailCss} participants={participants} userInfo={userInfo}  updateEmoji={updateEmoji} showName={showName} handleDownloadFile={handleDownloadFile} isLoadingFile={isLoadingFile} />;
      case 'message_is_voice':
        return <ReceiverVoiceMessageComponent isMobile={isMobile} message={message} scrollToMessage={scrollToMessage} isGroup={isGroup} showImage={showImage}  handleTailCss={handleTailCss} participants={participants} userInfo={userInfo}  updateEmoji={updateEmoji} showName={showName} handleDownloadFile={handleDownloadFile} isLoadingFile={isLoadingFile} />;
      case 'only_message':
        return <ReceiverMessageOnlyComponent isMobile={isMobile} message={message} scrollToMessage={scrollToMessage} isGroup={isGroup} showImage={showImage}  handleTailCss={handleTailCss} participants={participants} userInfo={userInfo}  updateEmoji={updateEmoji} showName={showName} shouldRoundTopCorner={shouldRoundTopCorner} shouldRoundBottomCorner={shouldRoundBottomCorner}/>;
      case 'only_emojis':
        return <ReceiverEmojisOnlyMessageComponent isMobile={isMobile} message={message} scrollToMessage={scrollToMessage} isGroup={isGroup} showImage={showImage} participants={participants} userInfo={userInfo}  updateEmoji={updateEmoji} emojiTextSize={emojiTextSize}  />;
      default:
        return <div />;
    }
  };

return (
  
  <div>
  {isMobile ? (
    <div 
        style={styles}
        className={`w-full flex items-end relative justify-start mobile-message-functions
        ${contextMenu && contextMenu.message && contextMenu.message.message_uuid == message.message_uuid && 'bg-slate-500/20 rounded-xl'}
        `} 
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTouchMove={handleTouchEnd}
        // onContextMenu={(e) => onRightClick(e, message,message.user_username, userInfo.user_username, 'onContextMenu' )}
    >
        {renderMessage()}
    </div>
  ):(
    <div 
        style={styles}
        className={`w-full flex items-end relative justify-start
        ${contextMenu && contextMenu.message && contextMenu.message.message_uuid == message.message_uuid && 'bg-slate-500/20 rounded-xl'}
        `} 
        onContextMenu={(e) => onRightClick(e, message,message.user_username, userInfo.user_username )}
    >
        {renderMessage()}
    </div>

  )}
</div>
)
}

export default ReceiverMessageComponent