import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';


function MobileRightDrawer({children}) {
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams()
    const [itemsQueried, setItemsQueried] = useState(0)

    useEffect(() => {
        // Gettting search parameters from URL
            let currentSearch = location.search
            let params = new URLSearchParams(currentSearch);
            console.log('params', params)

            // Remove the 'select' parameter
            params.delete('select');
            params.delete('operations_expanded');
            params.delete('assets_expanded');
            params.delete('platform');
            params.delete('time_period');
            params.delete('from');
            let count = 0;
            params.forEach((value, key) => {
                count++;
            });
            console.log('count', count)
            setItemsQueried(count)

    },[location])

    const handleResetNavigation = () => {
        let select = searchParams.getAll('select')
        let platform = searchParams.getAll('platform')
        let time_period = searchParams.getAll('time_period')
        let from = searchParams.getAll('from')
        let to = searchParams.getAll('to')
        
        setSearchParams({select, platform, time_period, from, to}) 
        

    }
  return (
    <>
      <motion.button
            className="mb-2 relative"
            onClick={() => setIsOpen(!isOpen)}
            whileHover={{ scale: 1.15 }}
            whileTap={{ scale: 0.9 }}
            transition={{ type: "spring", stiffness: 400, damping: 17 }}
        >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className='h-9 w-9 text-blue-600 border border-gray-200 dark:border-gray-700 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 transition ease-out delay-100 hover:-translate-y-0.5 hover:bg-transparent hover:translate-y-0' >
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 13.5V3.75m0 9.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 0 1 0 3m0-3a1.5 1.5 0 0 0 0 3m0 9.75V10.5" />
            </svg>
            {(itemsQueried && itemsQueried > 0 ) ? (
              <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-medium leading-none text-white transform translate-x-1/2 -translate-y-1/2 bg-blue-600 rounded-full">{itemsQueried}</span>
            ): <div className="absolute" />}
        </motion.button> 
      <div 
        className={`fixed z-[51] w-full top-0 right-0 h-full bg-white dark:bg-slate-800 shadow-iotflows-lg transform transition-transform duration-300 ease-in-out ${
          isOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        <div className='grid grid-cols-3 gap-2 p-1 items-center border-b-[0.01em] border-gray-200 dark:border-gray-700'>
            <button 
                onClick={() => setIsOpen(false)}
                className='text-blue-500 dark:text-blue-500 hover:text-blue-600 dark:hover:text-blue-400 p-1'
            >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-7 h-7">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                </svg>
            </button>
            <div className='text-base text-center text-gray-600 dark:text-gray-300'>
                Filters
            </div>
            <button 
                // onClick={() => navigate(`/${selectedOrganization}/assets/selected-asset/${asset.asset_uuid}?platform=mobile`)}
                onClick={handleResetNavigation}
                className='text-base text-end text-blue-500 dark:text-blue-400 hover:text-blue-600 dark:hover:text-blue-500 pr-1'
                >
                    Reset
                {/* <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8 text-gray-300 ">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg> */}
            </button>
        </div>
        <div className="p-4">
          {children}
        </div>
      </div>
    </>
  )
}

export default MobileRightDrawer