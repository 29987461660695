import { useEffect, useState } from "react";
import { DateRangePicker, defaultStaticRanges, defaultInputRanges } from "react-date-range";
import { motion, AnimatePresence } from "framer-motion";
import {
    addDays,
    endOfDay,
    startOfDay,
    startOfYear,
    startOfMonth,
    endOfMonth,
    endOfYear,
    addMonths,
    addYears,
    startOfWeek,
    endOfWeek,
    isSameDay,
    differenceInCalendarDays,
    parseISO, 
    format
  } from "date-fns";
import { useLocation, useSearchParams } from "react-router-dom";
import MonthlyPicker from "../../dateRangePickers/MonthlyPicker";
import QuarterPicker from "../../dateRangePickers/QuarterPicker";
import YearPicker from "../../dateRangePickers/YearPicker";
import { globalSelectedOrganizationTimezone } from "../../../atoms/orgStates";
import { useRecoilValue } from "recoil";

const today = new Date();// Get the current UTC time

const localOffsetMin = today.getTimezoneOffset(); // Get the offset in minutes from local computer
const localOffsetHrs = localOffsetMin/60 // convert it to hours
const formattedLocalOffsetHrs = localOffsetHrs.toFixed(0).toString().padStart(2, '0'); // Insert 0 in beginning if needed

const mondaySixWeeksAgo = new Date(today.getTime() - 6 * 7 * 24 * 60 * 60 * 1000);
// Force startDate to Monday if not already
if (mondaySixWeeksAgo.getDay() !== 1) {
    mondaySixWeeksAgo.setDate(mondaySixWeeksAgo.getDate() - (mondaySixWeeksAgo.getDay() % 7) + 1);
}
mondaySixWeeksAgo.setHours(0, 0, 0, 0)

let yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000)
yesterday.setHours(0, 0, 0, 0)

let sevenDaysAgo = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000)
sevenDaysAgo.setHours(0, 0, 0, 0)


let lastSunday = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000)
// Force endDate to Sunday if not already
if (lastSunday.getDay() !== 0) {
    lastSunday.setDate(lastSunday.getDate() + (7 - lastSunday.getDay()));
}
lastSunday.setHours(0, 0, 0, 0)
// lastSunday.setHours(23, 59, 59, 999)



const now = new Date();
const maxCalendarDate = new Date(now.getTime()+ 1 * 24 * 60 * 60 * 1000)
const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const quarters = [
    {
      "quarter": 'Q1',
      "startMonth": 0,
      "startDay": 1,
      "endMonth": 2,
      "endDay": 31
    },
    {
      "quarter": 'Q2',
      "startMonth": 3,
      "startDay": 1,
      "endMonth": 5,
      "endDay": 30,
    },
    {
      "quarter": 'Q3',
      "startMonth": 6,
      "startDay": 1,
      "endMonth": 8,
      "endDay": 30,
    },
    {
      "quarter": 'Q4',
      "startMonth": 9,
      "startDay": 1,
      "endMonth": 11,
      "endDay": 31,
    }
  ]


function DateRangeFilterAdvancedBtn() {
    const selectedOrganizationTimeZone = useRecoilValue(globalSelectedOrganizationTimezone);
    const [showCalendar, setShowCalendar] = useState(false)
    // const [startDate, setStartDate] = useState(new Date(now.getTime() - 6 * 24 * 60 * 60 * 1000))
    // const [endDate, setEndDate] = useState(new Date())
    const [startTempDate, setStartTempDate] = useState(mondaySixWeeksAgo)
    const [endTempDate, setEndTempDate] = useState(lastSunday)
    const [searchParams, setSearchParams] = useSearchParams()
    const location = useLocation();
    const [selectedTimePeriod, setSelectedTimePeriod] = useState('weekly')
    const [startDate, setStartDate] = useState(mondaySixWeeksAgo);
    const [endDate, setEndDate] = useState(lastSunday);
    const [dateString, setDateString] = useState('')
    const [selectedMonth, setSelectedMonth] = useState([]);
    const [selectedQuarter, setSelectedQuarter] = useState([]);
    const [selectedYear, setSelectedYear] = useState([]);
    const [isMobile, setIsMobile] = useState(false)

    const selectionRange = {
        startDate: startTempDate,
        endDate: endTempDate,
        key:'selectionRange',
        background:['#f43f5e']
        
    }

    useEffect(() => {
        let currentIsMobile = searchParams.get('platform')
        if(currentIsMobile === 'mobile'){
            setIsMobile(true)
        }else{
            setIsMobile(false)
        }

    },[])

    useEffect(() => {
        // this will be used to set the 6 weeks starting point in the reports
        let currentSearch = location.search
        if(currentSearch){
            let fromDate = searchParams.get('from')
            let toDate = searchParams.get('to')
            let period = searchParams.get('time_period')
            if(period === null && toDate === null && fromDate === null ){
                let select = searchParams.getAll('select')
                let period = 'weekly'
                let dateStartObject  = mondaySixWeeksAgo.toISOString().slice(0, 10)
                let startDateString = `${dateStartObject}T00:00:00${selectedOrganizationTimeZone.value}`
                let start = startDateString

                let dateEndObject  = lastSunday.toISOString().slice(0, 10)
                let endDateString = `${dateEndObject}T00:00:00${selectedOrganizationTimeZone.value}`
                let end = endDateString
                if(select[0] === 'historical_report'){
                    period = 'daily'
                    dateStartObject  = sevenDaysAgo.toISOString().slice(0, 10)
                    startDateString = `${dateStartObject}T00:00:00${selectedOrganizationTimeZone.value}`
                    start = startDateString

                    dateEndObject  = yesterday.toISOString().slice(0, 10)
                    endDateString = `${dateEndObject}T00:00:00${selectedOrganizationTimeZone.value}`
                    end = endDateString
                }

                let departments = searchParams.getAll('departments')
                let parts = searchParams.getAll('parts')
                let machine_types = searchParams.getAll('machine_types')
                let tags = searchParams.getAll('tags')
                let group_by = searchParams.getAll('group_by')
                let sort_by = searchParams.getAll('sort_by')
                let calculate_oee_by = searchParams.getAll('calculate_oee_by')
                let shift_name = searchParams.getAll('shift_name')
                let shift_start_time = searchParams.getAll('shift_start_time')
                let shift_date = searchParams.getAll('shift_date')
                let shift_entire_day = searchParams.getAll('shift_entire_day')
                let shift_by_min = searchParams.getAll('shift_by_min')
                let maximize = searchParams.getAll('maximize')
                let view = searchParams.getAll('view')
                let url_token = searchParams.getAll('url_token')
                let operations_expanded = searchParams.getAll('operations_expanded')
                let assets_expanded = searchParams.getAll('assets_expanded')
                let platform = searchParams.getAll('platform')
                let is_god_view_enabled = searchParams.getAll('is_god_view_enabled')
                let users = searchParams.getAll('users')
                let text = searchParams.getAll('text')
                let downtime_severity_uuids = searchParams.getAll('downtime_severity_uuids')
                let only_with_notes = searchParams.getAll('only_with_notes')
                let group_by_advanced = searchParams.getAll('group_by_advanced')
                let machines = searchParams.getAll('machines')
                let sort_by_advanced = searchParams.getAll('sort_by_advanced')
                let show_percentage = searchParams.getAll('show_percentage')
                let exclude_none_in_oee = searchParams.getAll('exclude_none_in_oee')
                let operations = searchParams.getAll('operations')
                let exclude_unknown_in_oee = searchParams.getAll('exclude_unknown_in_oee')
        
                setSearchParams({select, departments, parts, machine_types, tags, group_by, sort_by, calculate_oee_by, shift_name, shift_start_time, shift_date, shift_entire_day, shift_by_min, maximize, view, url_token, operations_expanded, assets_expanded, platform, is_god_view_enabled, users, text, downtime_severity_uuids, only_with_notes, group_by_advanced, machines, sort_by_advanced, show_percentage, time_period:period, from: start, to:end, exclude_none_in_oee, operations, exclude_unknown_in_oee})
            }
        }

    }, [])
    
    useEffect(() => {
        // Gettting search parameters from URL
        let currentSearch = location.search
        if(currentSearch){

            let fromDate = searchParams.get('from')
            let parsedFromDate = ''
            if(fromDate && startDate != fromDate){
                const dateSplitStart = fromDate.split("T")
                const dateStringFormattedStart = `${dateSplitStart[0]}T${formattedLocalOffsetHrs}:00:00.999Z`
                parsedFromDate = parseISO(dateStringFormattedStart)
                // console.log('Url parsedFromDateISO', parsedFromDate)
                setStartDate(dateStringFormattedStart)
                setStartTempDate(parsedFromDate)

                // parsedFromDate = parseISO(fromDate)
                // setStartDate(fromDate)
            }

            let toDate = searchParams.get('to')
            let parsedToDate = ''
            if(toDate && endDate != toDate){
                const dateSplitEnd = toDate.split("T")
                const dateStringFormattedEnd = `${dateSplitEnd[0]}T${formattedLocalOffsetHrs}:00:00.999Z`
                parsedToDate = parseISO(dateStringFormattedEnd)
                setEndDate(dateStringFormattedEnd)
                setEndTempDate(parsedToDate)

                // parsedToDate = parseISO(toDate);
                // setEndTempDate(parsedToDate)
                // setEndDate(toDate)
            }

        

            let period = searchParams.get('time_period')
            if(period && period.length > 0){
                setSelectedTimePeriod(period)
            }
            if(period == 'weekly'){
                var start_wn = getWeekNumberFromDate(parsedFromDate)
                var end_wn = getWeekNumberFromDate(parsedToDate)
                let string = `${start_wn} -> ${end_wn}`
                setDateString(string)
            }
            if(period == 'monthly'){
                let monthArray = []
                if(parsedFromDate){
                    const from = new Date(parsedFromDate);
                    const monthIndex = from.getMonth(); // Months are 0-indexed, so add 1
                    const year = from.getFullYear();
                    monthArray.push({
                        month: months[monthIndex],
                        year: year
                    })
                }
                if(parsedToDate){
                    const to = new Date(parsedToDate)
                    const monthIndex = to.getMonth(); // Months are 0-indexed, so add 1
                    const year = to.getFullYear();
                    monthArray.push({
                        month: months[monthIndex],
                        year: year
                    })
                }

                setSelectedMonth(monthArray)
            }else if(period == 'quarterly'){
                let quarterArray = []
                if(parsedFromDate){
                    const from = new Date(parsedFromDate);
                    let qString = ''
                    const monthIndex = from.getMonth(); // Months are 0-indexed, so add 1
                    if(monthIndex <= 2){
                        qString = 'Q1'
                    }else if(monthIndex > 2 && monthIndex <= 5){
                        qString = 'Q2'
                    }else if(monthIndex > 5 && monthIndex <= 8){
                        qString = 'Q3'
                    }else if(monthIndex > 8 && monthIndex <= 11){
                        qString = 'Q4'
                    }
                    const year = from.getFullYear();
                    quarterArray.push({
                        q: qString,
                        year: year
                    })
                }
                if(parsedToDate){
                    const to = new Date(parsedToDate)
                    let qString = ''
                    const monthIndex = to.getMonth(); // Months are 0-indexed, so add 1
                    if(monthIndex <= 2){
                        qString = 'Q1'
                    }else if(monthIndex > 2 && monthIndex <= 5){
                        qString = 'Q2'
                    }else if(monthIndex > 5 && monthIndex <= 8){
                        qString = 'Q3'
                    }else if(monthIndex > 8 && monthIndex <= 11){
                        qString = 'Q4'
                    }
                    const year = to.getFullYear();
                    quarterArray.push({
                        q: qString,
                        year: year
                    })
                }
                setSelectedQuarter(quarterArray)
            }else if(period == 'yearly'){
                let yearArray = []
                if(parsedFromDate){
                    const from = new Date(parsedFromDate);
                    const year = from.getFullYear();
                    yearArray.push({
                        year: year
                    })
                }
                if(parsedToDate){
                    const to = new Date(parsedToDate)
                    const year = to.getFullYear();
                    yearArray.push({
                        year: year
                    })
                }
                setSelectedYear(yearArray)
            }
        }

    }, [location])


    useEffect(() => {
        // Gettting search parameters from URL
            if(startDate === null || endDate === null){
                setStartDate(new Date(now.getTime() - 6 * 24 * 60 * 60 * 1000))
                setEndDate(new Date())
            }

        }, [startDate,endDate])


    const handleOpenCalendar = () => {
        setShowCalendar(!showCalendar)
    }


    const closeCalendar = () => {
        setShowCalendar(false)
    }

    function getWeekNumberFromDate(dateString) {
        // Parse the date string
        const parsedDate = new Date(dateString);
      
        // Ensure the parsed date is valid
        if (isNaN(parsedDate.getTime())) {
        //   console.error('Invalid date format');
          return null;
        }
      
        // Calculate the ISO week number
        const dayOfWeek = parsedDate.getUTCDay() || 7; // Sunday is 7, but we want it to be 0
        const currentDate = new Date(parsedDate);
        currentDate.setUTCDate(parsedDate.getUTCDate() + 4 - dayOfWeek);
      
        const yearStart = new Date(Date.UTC(currentDate.getUTCFullYear(), 0, 1));
        const weekNumber = Math.ceil(((currentDate - yearStart) / 86400000 + 1) / 7);

        let value = dateString.getFullYear() + ' Week ' + weekNumber 
      
        return value ;
    }

      

    const handleSelect = (ranges) => {
        const newStartDate = new Date(ranges.selectionRange.startDate);
        const newEndDate = new Date(ranges.selectionRange.endDate);
        if(selectedTimePeriod === 'weekly'){
        
            // Force startDate to Monday if not already
            if (newStartDate.getDay() !== 1) {
                newStartDate.setDate(newStartDate.getDate() - (newStartDate.getDay() % 7) + 1);
                newStartDate.setHours(0, 0, 0, 0)
            }
        
            // Force endDate to Sunday if not already
            if (newEndDate.getDay() !== 0) {
                newEndDate.setDate(newEndDate.getDate() + (7 - newEndDate.getDay()));
                newEndDate.setHours(0, 0, 0, 0) // Setting this to start of day because Mahmoud is doing it from his side
                // newEndDate.setHours(23, 59, 59, 999)
            }
    
            var start_wn = getWeekNumberFromDate(newStartDate)
            var end_wn = getWeekNumberFromDate(newEndDate)
            let string = `${start_wn} -> ${end_wn}`
            setDateString(string)
    
    
            
            setStartTempDate(newStartDate)
            setEndTempDate(newEndDate)
        }else {
            newStartDate.setHours(0, 0, 0, 0)
            newEndDate.setHours(0, 0, 0, 0) // Setting this to start of day because Mahmoud is doing it from his side
            // newEndDate.setHours(23, 59, 59, 999)
            setStartTempDate(newStartDate)
            setEndTempDate(newEndDate)

        }
    }

    const formatDate = (date) => {
        if(date){
            var currentDate = new Date(date)
            var shortCurrentDate = currentDate.getMonth()+1 + "/" + currentDate.getDate() + "/" + currentDate.getFullYear().toString()
            return shortCurrentDate;

        }
    }

    const handleSelectDaily = () => {
        setSelectedYear([])
        setSelectedQuarter([])
        setSelectedMonth([])
        setDateString('')
        setStartTempDate(new Date(now.getTime() - 3 * 24 * 60 * 60 * 1000))
        setEndTempDate(new Date())
        setSelectedTimePeriod('daily')
    }

    const handleSelectWeekly = () => {
        

        let newStartDate = new Date(now.getTime() - 10 * 24 * 60 * 60 * 1000)
        let newEndDate = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000)

        // Force startDate to Monday if not already
        if (newStartDate.getDay() !== 1) {
            newStartDate.setDate(newStartDate.getDate() - (newStartDate.getDay() % 7) + 1);
            newStartDate.setHours(0, 0, 0, 0)
        }
    
        // Force endDate to Sunday if not already
        if (newEndDate.getDay() !== 0) {
            newEndDate.setDate(newEndDate.getDate() + (7 - newEndDate.getDay()));
            newEndDate.setHours(0, 0, 0, 0) // Setting this to the start of the day because Mahmoud is moving it to the next day himself
            // newEndDate.setHours(23, 59, 59, 999)
        }

        var start_wn = getWeekNumberFromDate(newStartDate)
        var end_wn = getWeekNumberFromDate(newEndDate)
        let string = `${start_wn} -> ${end_wn}`
        setDateString(string)


        
        setStartTempDate(newStartDate)
        setEndTempDate(newEndDate)

        setSelectedTimePeriod('weekly')
        setSelectedYear([])
        setSelectedQuarter([])
        setSelectedMonth([])
    }

    const handleSelectMonthly = () => {

        setSelectedTimePeriod('monthly')
        setDateString('')
        setSelectedYear([])
        setSelectedQuarter([])
        setSelectedMonth([])
    }

    const handleSelectQuarterly = () => {

        setSelectedTimePeriod('quarterly')
        setDateString('')
        setSelectedYear([])
        setSelectedQuarter([])
        setSelectedMonth([])
    }

    const handleSelectYearly = () => {

        setSelectedTimePeriod('yearly')
        setDateString('')
        setSelectedYear([])
        setSelectedQuarter([])
        setSelectedMonth([])
    }



    const handleApplyDates = () => {
        
        setShowCalendar(false)
        
        let fromParameter = ''
        let toParameter = ''
        if(selectedTimePeriod == 'daily' || selectedTimePeriod == 'weekly'){
            let dateStart = new Date(startTempDate);
            let dateStartObject  = dateStart.toISOString().slice(0, 10)
            let startDateString = `${dateStartObject}T00:00:00${selectedOrganizationTimeZone.value}`
            fromParameter = startDateString

            let dateEnd = new Date(endTempDate);
            let dateEndObject  = dateEnd.toISOString().slice(0, 10)
            let endDateString = `${dateEndObject}T00:00:00${selectedOrganizationTimeZone.value}`
            toParameter = endDateString


            // startTempDate.setHours(0, 0, 0, 0)
            // endTempDate.setHours(23, 59, 59, 999)
            // fromParameter = startTempDate.toISOString()
            // toParameter = endTempDate.toISOString()
            setStartDate(startTempDate)
            setEndDate(endTempDate)
        }else if(selectedTimePeriod == 'monthly' && Object.keys(selectedMonth).length > 0){

            if(Object.keys(selectedMonth).length === 1){
                let startOfMonth = new Date(selectedMonth[0].year, months.indexOf(selectedMonth[0].month), 1)
                startOfMonth.setHours(0, 0, 0, 0)
                let dateStartObject  = startOfMonth.toISOString().slice(0, 10)
                let startDateString = `${dateStartObject}T00:00:00${selectedOrganizationTimeZone.value}`
                fromParameter = startDateString

                const nextMonth = new Date(selectedMonth[0].year, months.indexOf(selectedMonth[0].month) + 1, 1);
                const lastDayOfMonth = new Date(nextMonth.getFullYear(), nextMonth.getMonth(), 0);
                lastDayOfMonth.setHours(0, 0, 0, 0) // Setting this to start of day because Mahmoud is doing it from his side
                let dateEndObject  = lastDayOfMonth.toISOString().slice(0, 10)
                let endDateString = `${dateEndObject}T00:00:00${selectedOrganizationTimeZone.value}`
                toParameter = endDateString

                // lastDayOfMonth.setHours(23, 59, 59, 999)
                // fromParameter = startOfMonth.toISOString()
                // toParameter = lastDayOfMonth.toISOString()
                setStartDate(startOfMonth)
                setEndDate(lastDayOfMonth)

            }else if(Object.keys(selectedMonth).length >= 2){
                let startOfMonth = new Date(selectedMonth[0].year, months.indexOf(selectedMonth[0].month), 1)
                startOfMonth.setHours(0, 0, 0, 0)
                let dateStartObject  = startOfMonth.toISOString().slice(0, 10)
                let startDateString = `${dateStartObject}T00:00:00${selectedOrganizationTimeZone.value}`
                fromParameter = startDateString

                const nextMonth = new Date(selectedMonth[1].year, months.indexOf(selectedMonth[1].month) + 1, 1);
                const lastDayOfMonth = new Date(nextMonth.getFullYear(), nextMonth.getMonth(), 0);
                lastDayOfMonth.setHours(0, 0, 0, 0) // Setting this to start of day because Mahmoud is doing it from his side
                let dateEndObject  = lastDayOfMonth.toISOString().slice(0, 10)
                let endDateString = `${dateEndObject}T00:00:00${selectedOrganizationTimeZone.value}`
                toParameter = endDateString

                // lastDayOfMonth.setHours(23, 59, 59, 999)
                // fromParameter = startOfMonth.toISOString()
                // toParameter = lastDayOfMonth.toISOString()
                setStartDate(startOfMonth)
                setEndDate(lastDayOfMonth)

            }
        }else if(selectedTimePeriod == 'quarterly' && Object.keys(selectedQuarter).length > 0){
            let startOfQuarter = ''
            let lastOfQuarter = ''

            if(Object.keys(selectedQuarter).length === 1){
                quarters.map(q => {
                    if(q.quarter == selectedQuarter[0].q){
                        startOfQuarter = new Date(selectedQuarter[0].year, q.startMonth, q.startDay)
                        lastOfQuarter = new Date(selectedQuarter[0].year, q.endMonth, q.endDay)
                    }
                })

            }else if(Object.keys(selectedQuarter).length >= 2){
                selectedQuarter.map((selectedQ, index) => {
                    quarters.map(q => {
                        if(index == 0 && q.quarter == selectedQ.q){
                            startOfQuarter = new Date(selectedQ.year, q.startMonth, q.startDay)
                        }else if(index == 1 && q.quarter == selectedQ.q){
                            lastOfQuarter = new Date(selectedQ.year, q.endMonth, q.endDay)
                        }


                        
                    })
                })
            }
            startOfQuarter.setHours(0, 0, 0, 0)
            let dateStartObject  = startOfQuarter.toISOString().slice(0, 10)
            let startDateString = `${dateStartObject}T00:00:00${selectedOrganizationTimeZone.value}`
            fromParameter = startDateString

            lastOfQuarter.setHours(0, 0, 0, 0) // Setting this to start of day because Mahmoud is doing it from his side
            let dateEndObject  = lastOfQuarter.toISOString().slice(0, 10)
            let endDateString = `${dateEndObject}T00:00:00${selectedOrganizationTimeZone.value}`
            toParameter = endDateString

            // lastOfQuarter.setHours(23, 59, 59, 999)
            // fromParameter = startOfQuarter.toISOString()
            // toParameter = lastOfQuarter.toISOString()
            setStartDate(startOfQuarter)
            setEndDate(lastOfQuarter)
        }else if(selectedTimePeriod == 'yearly' && Object.keys(selectedYear).length > 0){
            let startOfYear = ''
            let lastOfYear = ''

            if(Object.keys(selectedYear).length === 1){
                startOfYear = new Date(selectedYear[0].year, 0, 1)
                lastOfYear = new Date(selectedYear[0].year, 11, 31)

            }else if(Object.keys(selectedYear).length >= 2){
                selectedYear.map((selectedY, index) => {
                    if(index == 0 ){
                        startOfYear = new Date(selectedY.year, 0, 1)
                    }else if(index == 1){
                        lastOfYear = new Date(selectedY.year, 11, 31)
                    }
                })
            }
            startOfYear.setHours(0, 0, 0, 0)
            let dateStartObject  = startOfYear.toISOString().slice(0, 10)
            let startDateString = `${dateStartObject}T00:00:00${selectedOrganizationTimeZone.value}`
            fromParameter = startDateString


            lastOfYear.setHours(0, 0, 0, 0) // Setting this to start of day because Mahmoud is doing it from his side
            let dateEndObject  = lastOfYear.toISOString().slice(0, 10)
            let endDateString = `${dateEndObject}T00:00:00${selectedOrganizationTimeZone.value}`
            toParameter = endDateString

            // lastOfYear.setHours(23, 59, 59, 999)
            // fromParameter = startOfYear.toISOString()
            // toParameter = lastOfYear.toISOString()
            setStartDate(startOfYear)
            setEndDate(lastOfYear)
        }
    
        // tempMembers.map(member => {
        //     parameters.push(member.user_username)
        // })
        let select = searchParams.getAll('select')
        let departments = searchParams.getAll('departments')
        let parts = searchParams.getAll('parts')
        let machine_types = searchParams.getAll('machine_types')
        let tags = searchParams.getAll('tags')
        let group_by = searchParams.getAll('group_by')
        let sort_by = searchParams.getAll('sort_by')
        let calculate_oee_by = searchParams.getAll('calculate_oee_by')
        let shift_name = searchParams.getAll('shift_name')
        let shift_start_time = searchParams.getAll('shift_start_time')
        let shift_date = searchParams.getAll('shift_date')
        let shift_entire_day = searchParams.getAll('shift_entire_day')
        let shift_by_min = searchParams.getAll('shift_by_min')
        let maximize = searchParams.getAll('maximize')
        let view = searchParams.getAll('view')
        let url_token = searchParams.getAll('url_token')
        let operations_expanded = searchParams.getAll('operations_expanded')
        let assets_expanded = searchParams.getAll('assets_expanded')
        let platform = searchParams.getAll('platform')
        let is_god_view_enabled = searchParams.getAll('is_god_view_enabled')
        let users = searchParams.getAll('users')
        let text = searchParams.getAll('text')
        let downtime_severity_uuids = searchParams.getAll('downtime_severity_uuids')
        let only_with_notes = searchParams.getAll('only_with_notes')
        let group_by_advanced = searchParams.getAll('group_by_advanced')
        let machines = searchParams.getAll('machines')
        let sort_by_advanced = searchParams.getAll('sort_by_advanced')
        let show_percentage = searchParams.getAll('show_percentage')
        let exclude_none_in_oee = searchParams.getAll('exclude_none_in_oee')
        let operations = searchParams.getAll('operations')
        let exclude_unknown_in_oee = searchParams.getAll('exclude_unknown_in_oee')

        setSearchParams({select, departments, parts, machine_types, tags, group_by, sort_by, calculate_oee_by, shift_name, shift_start_time, shift_date, shift_entire_day, shift_by_min, maximize, view, url_token, operations_expanded, assets_expanded, platform, is_god_view_enabled, users, text, downtime_severity_uuids, only_with_notes, group_by_advanced, machines, sort_by_advanced, show_percentage, time_period:selectedTimePeriod, from: fromParameter, to:toParameter, exclude_none_in_oee, operations, exclude_unknown_in_oee})
        
    }


  return (
    <div className={`relative  ${isMobile ? 'w-full':'max-w-[300px]'}`}>
        <motion.button  whileHover={{ scale: 1.03 }} whileTap={{ scale: 0.98 }}  
            className={`w-full px-2 py-1 text-sm font-normal text-gray-700 dark:text-gray-200 bg-white dark:bg-slate-600 bg-clip-padding bg-no-repeat border-[1px] border-slate-50 dark:border-slate-400 rounded shadow-iotflows-lg`} 
            onClick={handleOpenCalendar}
        >
            <span className='text-sm pl-2'>{formatDate(startDate)} {`-`} {formatDate(endDate)}</span>
        </motion.button>
        {showCalendar && (
            <div className={`absolute z-20 right-0 top-10 lg:right-0 flex flex-col bg-slate-700 rounded-xl  ${isMobile && 'min-w-[350px] max-h-[550px] overflow-y-scroll'} `}>
                <div className='flex justify-end bg-slate-700 p-1 rounded-t-xl'>
                    <button 
                        onClick={closeCalendar} 
                        className='rounded-full hover:bg-slate-800 p-1'>
                        <svg  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-gray-300 ">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <div className={`flex ${isMobile ? 'flex-col':'flex-row'}`}>
                    <div className='flex flex-col'>
                        <ul className={`flex flex-col text-gray-300  min-w-[200px] border-t-[1px] border-slate-500`}>
                            <li className={`${selectedTimePeriod === 'daily' ?    'text-blue-400':''} ' border-b-[1px] border-slate-500 pt-3 pb-2 pl-4 pr-4 text-sm hover:bg-slate-600 cursor-pointer`} onClick={handleSelectDaily}>Daily</li>
                            <li className={`${selectedTimePeriod === 'weekly' ?   'text-blue-400':''} ' border-b-[1px] border-slate-500 pt-3 pb-2 pl-4 pr-4 text-sm hover:bg-slate-600 cursor-pointer`} onClick={handleSelectWeekly}>Weekly</li>
                            <li className={`${selectedTimePeriod === 'monthly'?   'text-blue-400':''} ' border-b-[1px] border-slate-500 pt-3 pb-2 pl-4 pr-4 text-sm hover:bg-slate-600 cursor-pointer`} onClick={handleSelectMonthly}>Monthly</li>
                            <li className={`${selectedTimePeriod === 'quarterly'? 'text-blue-400':''} ' border-b-[1px] border-slate-500 pt-3 pb-2 pl-4 pr-4 text-sm hover:bg-slate-600 cursor-pointer`} onClick={handleSelectQuarterly}>Quarterly</li>
                            <li className={`${selectedTimePeriod === 'yearly' ?   'text-blue-400':''} ' border-b-[1px] border-slate-500 pt-3 pb-2 pl-4 pr-4 text-sm hover:bg-slate-600 cursor-pointer`} onClick={handleSelectYearly}>Yearly</li>
                        </ul>
                        <div className={`flex w-full ${isMobile ? 'mt-4':'mt-4 mb-4'}  justify-center px-2 min-h-[40px]`}>
                            {dateString.length > 0 &&
                                    <span className='mx-auto text-white text-sm font-semibold bg-blue-500 py-2 px-3 rounded-lg'>
                                        {dateString}

                                    </span>
                            }
                        </div>
                    </div>
                    <div className={`${isMobile ? 'w-full':'w-[341px] border-l-[1px] border-t-[1px]'}  border-slate-500`}>
                        {selectedTimePeriod === 'daily' || selectedTimePeriod === 'weekly' ?
                            (
                                <div className='customWrapper pr-2'>
                                    <DateRangePicker
                                        ranges={[selectionRange]}
                                        maxDate={maxCalendarDate}
                                        rangeColors={['#3b82f6']}
                                        onChange={handleSelect}
                                        staticRanges={[]}
                                        inputRanges={[]}
                                    />
                                </div>
                            ):
                            ''
                        }
                        {selectedTimePeriod === 'monthly' && 
                            <div className="px-2 pt-4 pb-8">
                                <MonthlyPicker setSelectedMonth={setSelectedMonth} selectedMonth={selectedMonth} setDateString={setDateString} />
                            </div>
                        }
                        {selectedTimePeriod === 'quarterly' && 
                            <div className="px-2 pt-4 pb-8">
                                <QuarterPicker setSelectedQuarter={setSelectedQuarter} selectedQuarter={selectedQuarter} setDateString={setDateString} />
                            </div>
                        }
                        {selectedTimePeriod === 'yearly' && 
                            <div className="px-2 pt-4 pb-8">
                                <YearPicker setSelectedYear={setSelectedYear} selectedYear={selectedYear} setDateString={setDateString} />
                            </div>
                        }
                    </div>
                </div>
                <div>
                <button  
                    onClick={handleApplyDates} 
                    className='w-full text-base p-2 bg-slate-600 text-white font-medium rounded-b-xl hover:bg-slate-900' >
                        Apply
                </button>
                </div>
            </div>
        )}
    </div>
  )
}

export default DateRangeFilterAdvancedBtn

