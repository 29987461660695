import { ArrowsExpandIcon, MenuIcon } from "@heroicons/react/outline"
import { ViewGridIcon, XIcon } from "@heroicons/react/solid"
import {  useEffect, useRef, useState } from "react"
import ListAssetView from './ListAssetView'
import CardAssetView from "./CardAssetView"
import AddAssetModal from "../components/modals/assetmodals/AddAssetModal"
import { motion, AnimatePresence } from "framer-motion";
import DepartmentFilterBtn from "../components/filterButtons/DepartmentFilterBtn"
import MachineTypeFilterBtn from "../components/filterButtons/MachineTypeFilterBtn"
import GroupByFilterBtn from "../components/filterButtons/GroupByFilterBtn"
import SortByFilterBtn from "../components/filterButtons/SortByFilterBtn"
import LineAreaCart from "../components/charts/LineAreaCart" 
import EditAssetsListModal from "../components/modals/assetmodals/EditAssetsListModal"
import TagFilterBtn from "../components/filterButtons/TagFilterBtn"
import { globalSelectedOrganization } from "../atoms/orgStates"
import { getData, putData } from "../ApiCalls/DataApis"
import { apiLink } from "../ApiCalls/ApisVariables"
import { useRecoilState, useRecoilValue } from "recoil"
import ShiftFilterBtn from "../components/filterButtons/ShiftFilterBtn"
import { useLocation, useSearchParams } from "react-router-dom"
import Switch from '@mui/material/Switch';
import { sendUrlLinkModalState } from "../atoms/modalStates"
import MqttService from "../core/services/MqttService"
import DateRangeComponent from "../components/forms/DateRangeComponent"
import AddMachineModal from "../components/modals/assetmodals/AddMachineModal"
import toast from "react-hot-toast"
import { userAuthorization, userInfo } from "../atoms/userStates"
import CardAssetLargeView from "./CardAssetLargeView"
import CardAssetOeeLargeView from "./CardAssetOeeLargeView"
import ProgressGaugeLarge from "../components/charts/ProgressGaugeLarge"
import ProgressGaugeXL from "../components/charts/ProgressGaugeXL"
import ProgressGauge from "../components/charts/ProgressGauge"
import OeeGoalOrgModal from "../components/modals/assetmodals/OeeGoalOrgModal"
import { LinearProgress } from "@mui/material"


const MINUTE_MS = 60*1000*5;


function AssetsOverviewPage() {
    const isUserAuthorized = useRecoilValue(userAuthorization);
    const [showGODview, setShowGODview] = useState(false)
    const [view, setView] = useState('')
    const [expand, setExpand] = useState(false)
    const [isShowingAssets, setIsShowingAssets] = useState(false)
    const selectedOrganization = useRecoilValue(globalSelectedOrganization);
    const [assetList, setAssetList] = useState([])
    const [assetInsights, setAssetInsights] = useState([])
    const [oeeChartData, setOeeChartData] = useState([])
    const [currentOee, setCurrentOee] = useState(0)
    const [oeeDayChange, setOeeDayChange] = useState(0)
    const [isOeeImproved, setIsOeeImproved] = useState(true)
    const location = useLocation();
    const [grayOutStatus, setGrayOutStatus] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()
    const [isSendUrlLinkModalOpen, setIsSendUrlLinkModalOpen] = useRecoilState(sendUrlLinkModalState)
    const [shiftForChart, setShiftForChart] = useState('')
    const [isMobile, setIsMobile] = useState(false)
    const [mqttMessageNodeUUID, setMqttMessageNodeUUID] = useState('')
    const [mqttMessageIsRunning, setMqttMessageIsRunning] = useState(null)
    const [mqttMessageTimestamp, setMqttMessageTimestamp] = useState(0)
    const [loadingChangedView, setLoadingChangedView] = useState(false)
    const [openOeeGoalModal, setOpenOeeGoalModal] = useState(false)
    const [totalMachines, setTotalMachines] = useState(null)
    const [user, setUser] = useRecoilState(userInfo)
    const prevUrlRef = useRef(null);
    const [orgOeeGoal, setOrgOeeGoal] = useState('')
    const [loadingAssets, setLoadingAssets] = useState(false)


    useEffect(() => {
        // apply all filters
        let select = searchParams.getAll('select')
        let parts = searchParams.getAll('parts')
        let departments = searchParams.getAll('departments')
        let machine_types = searchParams.getAll('machine_types')
        let tags = searchParams.getAll('tags')
        let group_by = searchParams.getAll('group_by')
        let sort_by = searchParams.getAll('sort_by')
        let shift_name = searchParams.getAll('shift_name')
        let shift_start_time = searchParams.getAll('shift_start_time')
        let shift_date = searchParams.getAll('shift_date')
        let shift_entire_day = searchParams.getAll('shift_entire_day')
        let shift_yesterday = searchParams.getAll('shift_yesterday')
        let url_token = searchParams.getAll('url_token')
        let operations_expanded = searchParams.getAll('operations_expanded')
        let assets_expanded = searchParams.getAll('assets_expanded')
        let platform = searchParams.getAll('platform')
        let is_god_view_enabled = searchParams.getAll('is_god_view_enabled')
        let maximize = searchParams.getAll('maximize')

        setSearchParams({select,parts, departments, machine_types, tags, group_by, sort_by, shift_name, shift_start_time, shift_date, shift_entire_day,shift_yesterday, maximize, url_token, operations_expanded, assets_expanded, platform, is_god_view_enabled})

    
    },[])

    
    
    useEffect(() => {
        // let calculate_oee_by = searchParams.getAll('calculate_oee_by')
        let maximize = searchParams.getAll('maximize')
        
        if(maximize == 'isExpanded' ){
            setExpand(true)
        }else{
            setExpand(false)
        }
        
        const timer = setTimeout(() => {
            setIsShowingAssets(true)
          }, 50);
          return () => clearTimeout(timer);
          

    },[])
    

    useEffect(() => {
      let currentIsMobile = searchParams.get('platform')
      if(currentIsMobile === 'mobile'){
        setIsMobile(true)
      }else{
        setIsMobile(false)
      }
    },[location])


    var prevSearchPath = ''
    useEffect(() => {
        try {
            if(selectedOrganization && location && location.pathname){
                let time = new Date().getTime()
                const currentUrl = location.pathname + location.search;
                if (prevUrlRef.current !== currentUrl) {
                    // URL path has changed
                    // console.log(time,"URL changed from", prevUrlRef.current, "to", currentUrl);
                    prevUrlRef.current = currentUrl; // Update stored URL
                    
                    getAssetsInsights()
                    getOrgAssets()
                    getOrgInfo()
                    getDailyOEE()
                  }
                

            }
            
        } catch (error) {
          console.log(error.message)
        }
    },[selectedOrganization, location])
    

    useEffect(() => {
        const interval = setInterval(() => {
          let time = new Date().getTime()
        // console.log('intergcal setting', time)
            getAssetsInsights()
            getOrgAssets()
            getOrgInfo()
            // console.log('Interval - calling Daily OEE')
            getDailyOEE()
        }, MINUTE_MS);
      
        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
      }, [selectedOrganization, location])
    
    
    

    useEffect(() => {
        if(Object.keys(user).length > 0){
            let currentIsMobile = searchParams.get('platform')
            
            if(currentIsMobile === 'mobile'){
                if(user.view_name === 'card_large_view'){
                    setView('card_large_view')
                }else if(user.view_name === 'card_oee_large_view'){
                    setView('card_oee_large_view')
                }else if(user.view_name === 'card_large_multi_ops_view'){
                    setView('card_large_multi_ops_view')
                }else{
                    setView('card_view') 
                }
            }else{
                // user preference
                if(user.view_name === 'card_large_view'){
                    setView('card_large_view')
                }else if(user.view_name === 'card_oee_large_view'){
                    setView('card_oee_large_view')
                }else if(user.view_name === 'card_large_multi_ops_view'){
                    setView('card_large_multi_ops_view')
                }else if(user.view_name === 'list_view'){
                    setView('list_view')
                }else{
                    setView('card_view')
                }
            }
        }
          

    },[user, location])

    useEffect(() => {
        let shiftDate = searchParams.getAll('shift_date')

        if(shiftDate && shiftDate.length > 0){
            setGrayOutStatus(true)
        }else{
            setGrayOutStatus(false)
        }

    }, [location])

    // MQTT Topic subcription to determine if a device has been publishing data
    useEffect(() => {
        try {
            if(selectedOrganization){
                setTimeout(() => {
                    //  MQTT Subcribe - We need a delay to ensure MQTT is connected before trying to subscribe
                    if(MqttService.isConnected) {
                    //   let activityTopic = `v1/organizations/${selectedOrganization}/senseai/nodes/+/events/#`
                      let activityTopic = `v1/organizations/${selectedOrganization}/senseai/nodes/+/events/activity`
                      MqttService.subscribe(activityTopic, onMQTTReceived)
                    }
                  }, 5000)
              
            }
            
        } catch (error) {
            
        }
    }, [selectedOrganization])

    useEffect(() => {
        // Determining if user had GOD view access
        getData(apiLink + '/v1/users/iotflows')
        .then(async response => {
          if(response && response.ok ){
            const data = await response.json();
            if(data && data.data.length > 0 && data.data[0].iotflows_member_role_id){
              setShowGODview(true)
            } 
          }
        });
  
      }, [])


    const onMQTTReceived = message => {
        try{ 
            let split = message.topic.split('/')
            const payloadMessage = JSON.parse(message.payloadString);
            
            if(split[4] == "nodes"){
                // console.log('payloadMessage', payloadMessage)
                let node_uuid = split[5]
                let currentTime = new Date().getTime()
                setMqttMessageNodeUUID(node_uuid)
                setMqttMessageTimestamp(currentTime)
                if(payloadMessage && payloadMessage.data && payloadMessage.data.running){
                    setMqttMessageIsRunning(payloadMessage.data.running)
                }else{
                    setMqttMessageIsRunning(null)
                }
            }
          }
          catch(e){
            console.log("Error in reading MQTT");
            console.log(e.message);
          }
    }
    
    

    const getDailyOEE = () => {
        try {
            if(selectedOrganization){
                const startTime = performance.now();

                let currentSearch = location.search
                getData(apiLink + '/v1/organizations/'+ selectedOrganization + '/daily-oee' + currentSearch )
                .then(async response => {
                    // JSON data parsed by response.json() call
                    if(response.ok ){
                    const data = await response.json();
                        if(data && data.data){
                            let chartData = []
                            // console.log('getDailyOEE', data.data)
                            data.data.oee_days.map(oeeData => {
                                let time = new Date(oeeData.d)
                                let seconds = time.getTime()
                                let oeePercent = 0
                                if(oeeData.oee){
                                    oeePercent = (oeeData.oee*100).toFixed(1)
                                    oeePercent = parseFloat(oeePercent)
                                }
                                let array = [seconds, oeePercent]
                                chartData.push(array)
                            })
                            setOeeChartData(chartData)

                            // let currentDayOee = ((data.data.today_oee)*100)
                            // if(currentDayOee && currentDayOee.toFixed(1)){
                            //     currentDayOee = currentDayOee.toFixed(1)
                            // }
                            // setCurrentOee(currentDayOee)
                            // if(data.data.shift_name === 'Default'){
                            //     setShiftForChart('All Day')    
                            // }else{
                            //     setShiftForChart(data.data.shift_name)
                            // }

                            // let oeeChangePercent = (data.data.oee_change)*100
                            // if(oeeChangePercent && oeeChangePercent.toFixed(1)){
                            //     oeeChangePercent = oeeChangePercent.toFixed(1)
                            // }
                            // oeeChangePercent = parseFloat(oeeChangePercent)
                            // if(isNaN(oeeChangePercent)){
                            //     oeeChangePercent = 0
                            // }
                            // setOeeDayChange(oeeChangePercent)
                            // if(oeeChangePercent >0){
                            //     setIsOeeImproved(true)
                            // }else{
                            //     setIsOeeImproved(false)
                            // }
                            
                            const duration = performance.now() - startTime;
                            console.log(`get DailyOEE took ${duration}ms`);
                        }
                    }
                })

            }
        } catch (error) {
            
        }
    }

    const getOrgInfo = () => {
        if(selectedOrganization){

            getData(apiLink + '/v1/organizations/' + selectedOrganization)
                .then(async response => {
                    if(response.ok ){
                    const data = await response.json();
                    if(data ){
                        setOrgOeeGoal(data.data[0].organization_oee_goal)
                    }
                    }
                    
                });
        }
    }

    const getOrgAssets = () => {
        if(selectedOrganization && !loadingAssets){
            setLoadingAssets(true)
            const startTime = performance.now();

            let currentSearch = location.search
            getData(apiLink + '/v1/organizations/'+ selectedOrganization + '/organization_assets' + currentSearch )
            .then(async response => {
                // JSON data parsed by response.json() call
                if(response.ok ){
                const data = await response.json();
                    if(data && data.data){
                        // console.log('Org Assets data', data)
                        setLoadingAssets(false)
                        setAssetList(data.data)

                        const duration = performance.now() - startTime;
                            console.log(`getOrgAssets took ${duration}ms`);
                    }else{
                        setLoadingAssets(false)
                    }
                }else{
                    setLoadingAssets(false)
                }
            })

        }

    }
    

    const getAssetsInsights = () => {
        if(selectedOrganization && location && location.pathname){
            let currentSearch = location.search
            getData(apiLink + '/v1/organizations/'+ selectedOrganization + '/assets-insights' + currentSearch )
            .then(async response => {
                // JSON data parsed by response.json() call
                if(response.ok ){
                const data = await response.json();
                    if(data && data.data){
                        setAssetInsights(data.data)
                        let assetsProducing = Number(data.data.assets_producting_count)
                        let assetsDown = Number(data.data.assets_not_producing_count)
                        let total = assetsProducing + assetsDown
                        setTotalMachines(total)

                        let oee = (Number(data.data.total_running_seconds) / (Number(data.data.total_not_running_seconds) + Number(data.data.total_running_seconds)))* 100

                        setCurrentOee(oee.toFixed(1))
                    }
                }
            })

        }

    }


    const formatTime =(seconds) => {
        let timeString = ''
        if(seconds){
            let totalMin = seconds/60
            let hours = totalMin/60
            hours = Math.floor(hours)
            let remMinutes = (totalMin - (hours*60))
            remMinutes = Math.floor(remMinutes)
            if(hours < 10 && hours >= 0){
                hours = '0' + hours 
            }
            if(remMinutes < 10 && remMinutes >= 0){
                remMinutes = '0' + remMinutes 
            }

            timeString = `${hours}:${remMinutes}h`

        }else{
            timeString = `00:00h`
            
        }
        return timeString
    }


    const handleOpenSendLinkModal = () => {
        setIsSendUrlLinkModalOpen(true)
    }


    const handleChangeUserView = (changedView) => {
        if(!loadingChangedView){
            setLoadingChangedView(true)
            // let isListView = true
            if(changedView == "card_view"){
                setView('card_view')
                // isListView = false
            }else if(changedView == "card_large_view"){
                setView('card_large_view')
                // isListView = false
            }else if(changedView == "card_oee_large_view"){
                setView('card_oee_large_view')
                // isListView = false
            } else if(changedView == "card_large_multi_ops_view"){
                setView('card_large_multi_ops_view')
            }else{
                setView('list_view')
            }


            try{
                putData(apiLink + '/v1/users/view', 
                {'view_name': changedView})
                  .then(async response => {
                    const data = await response.json();
                    if(response.ok){
                        setLoadingChangedView(false)
                        if(data && data.data){
                            // console.log('User Info?',data.data[0])
                            setUser(data.data[0])
                            toast.success("View settings changed")
                        }
                    }
                    else{
                      try{
                        setLoadingChangedView(false)
                        toast.error(data.message)
                      }catch (e){
                        toast.error('Something went wrong')
                        setLoadingChangedView(false)
                      }
                    }
                  })

            }catch (e) {
                setLoadingChangedView(false)
                toast.error(e.message)
            }
            // putData(apiLink + '/v1/users/listview', 
            // {'user_prefers_list_view': isListView})
            //   .then(async response => {
            //     const data = await response.json();
            //     if(response.ok){
            //         setLoadingChangedView(false)
            //         toast.success("View settings changed")
            //     }
            //     else{
            //       try{
            //         setLoadingChangedView(false)
            //         toast.error(data.message)
            //       }catch (e){
            //         toast.error('Something went wrong')
            //         setLoadingChangedView(false)
            //       }
            //     }
            //   })
        }
    }

    const handleExpandBtn = (value) => {
        setExpand(value)

        let isExpanded = 'isNotExpand'
        if(value){
            isExpanded = 'isExpanded'
        }

        // apply all filters
        let select = searchParams.getAll('select')
        let parts = searchParams.getAll('parts')
        let departments = searchParams.getAll('departments')
        let machine_types = searchParams.getAll('machine_types')
        let tags = searchParams.getAll('tags')
        let group_by = searchParams.getAll('group_by')
        let sort_by = searchParams.getAll('sort_by')
        let shift_name = searchParams.getAll('shift_name')
        let shift_start_time = searchParams.getAll('shift_start_time')
        let shift_date = searchParams.getAll('shift_date')
        let shift_entire_day = searchParams.getAll('shift_entire_day')
        let shift_yesterday = searchParams.getAll('shift_yesterday')
        let url_token = searchParams.getAll('url_token')
        let operations_expanded = searchParams.getAll('operations_expanded')
        let assets_expanded = searchParams.getAll('assets_expanded')
        let platform = searchParams.getAll('platform')
        let is_god_view_enabled = searchParams.getAll('is_god_view_enabled')

        setSearchParams({select,parts, departments, machine_types, tags, group_by, sort_by, shift_name, shift_start_time, shift_date, shift_entire_day,shift_yesterday, maximize: isExpanded, url_token, operations_expanded, assets_expanded, platform, is_god_view_enabled})



    }


    const handleCloseOeeGoalOrgModal = () => {
        setOpenOeeGoalModal(false)
    }

    const renderOeeGoal = (oee, goal) => {
        let goal_percent = Number(goal) * 100
        let numeric_oee = Number(oee)
        if(numeric_oee >= goal_percent){
            return (
                <span className='text-green-600'>{oee}%</span>
            )
        }else if(numeric_oee < goal_percent){
            return(
                <span className='text-red-600'>{oee}%</span>
            )
        }else{
            return (
                <span className='text-gray-500 dark:text-gray-400'>0%</span>
            )
        }
    }
    
  return (
    <div className='mt-2 p-1 h-screen scrollbar-hide'>
        {/* Filter section */}
        <div className={`${isMobile && 'hidden'} flex flex-col xl:flex-row xl:items-center mb-4`}>
            <div className={` hidden space-x-2  ${expand ? 'md:hidden' : 'md:inline-flex'} `}>
                {/* <PartFilterBtn select={'overview'} /> */}
                <DepartmentFilterBtn select={'overview'} />
                {/* <MachineTypeFilterBtn select={'overview'} /> */}
                <TagFilterBtn select={'overview'} />
                <GroupByFilterBtn select={'overview'} />
                <SortByFilterBtn select={'overview'} />
                <ShiftFilterBtn select={'overview'} /> 
            </div>

            <div className='flex md:mt-2 xl:mt-0 space-x-2 items-center ml-auto pl-2'>

                <motion.div
                    className=""
                    onClick={() => handleExpandBtn(true)}
                    whileHover={{ scale: 1.15 }}
                    whileTap={{ scale: 0.9 }}
                    transition={{ type: "spring", stiffness: 400, damping: 17 }}
                >
                    <ArrowsExpandIcon className='executeIcon hidden sm:inline-flex hover:bg-transparent hover:translate-y-0'  />
                </motion.div> 
                
                <div className='hidden sm:flex relative'>
                    <ViewGridIcon className={`${view === 'card_view' && 'bg-gray-200 dark:bg-gray-700'} executeIcon rounded-l-lg rounded-r-none`} onClick={() => handleChangeUserView('card_view')}/>
                    <button className={`${view === 'card_large_view' && 'bg-gray-200 dark:bg-gray-700'} border-l-0 border-r-0 executeIcon rounded-none`} onClick={() => handleChangeUserView('card_large_view')}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 0 0 2.25-2.25V6a2.25 2.25 0 0 0-2.25-2.25H6A2.25 2.25 0 0 0 3.75 6v2.25A2.25 2.25 0 0 0 6 10.5Zm0 9.75h2.25A2.25 2.25 0 0 0 10.5 18v-2.25a2.25 2.25 0 0 0-2.25-2.25H6a2.25 2.25 0 0 0-2.25 2.25V18A2.25 2.25 0 0 0 6 20.25Zm9.75-9.75H18a2.25 2.25 0 0 0 2.25-2.25V6A2.25 2.25 0 0 0 18 3.75h-2.25A2.25 2.25 0 0 0 13.5 6v2.25a2.25 2.25 0 0 0 2.25 2.25Z" />
                        </svg>
                    </button>
                    <button className={`${view === 'card_oee_large_view' && 'bg-gray-200 dark:bg-gray-700'} border-l-0 border-r-0 executeIcon rounded-none`} onClick={() => handleChangeUserView('card_oee_large_view')}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" viewBox="0 -960 960 960" fill="currentColor">
                            <path d="M120-440v-320q0-33 23.5-56.5T200-840h240v400H120Zm240-80Zm160-320h240q33 0 56.5 23.5T840-760v160H520v-240Zm0 720v-400h320v320q0 33-23.5 56.5T760-120H520ZM120-360h320v240H200q-33 0-56.5-23.5T120-200v-160Zm240 80Zm240-400Zm0 240Zm-400-80h160v-240H200v240Zm400-160h160v-80H600v80Zm0 240v240h160v-240H600ZM200-280v80h160v-80H200Z"/>
                        </svg>
                    </button>
                    <MenuIcon className={`${view === 'list_view' && 'bg-gray-200 dark:bg-gray-700'} executeIcon rounded-r-lg rounded-l-none`} onClick={() => handleChangeUserView('list_view')} />
                    {loadingChangedView && 
                        <div >
                            <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                            </svg>
                        </div>
                    }
                </div>
                {showGODview &&
                <AddMachineModal btnType='standardBtn' />
                }
                <EditAssetsListModal btnType='standardBtn' assetList={assetList} getOrgAssets={getOrgAssets} isUserAuthorized={isUserAuthorized} />
                <AddAssetModal btnType='standardBtn' getOrgAssets={getOrgAssets} isUserAuthorized={isUserAuthorized} />
            </div>
        </div>
        {/* Overall paramenters - OEE, uptime, downtime, machine production */}
        <div className="flex flex-col lg:flex-row">
            {/* Overall utilization chart */}
            <div className='grow'>
                <div className="flex flex-col bg-slate-50/50 dark:bg-slate-800 rounded-lg grow shadow-iotflows-lg p-2 ">
                    {/* <div className="flex pl-2 mt-0.5">
                        <div className='flex flex-col sm:flex-row items-baseline'>
                            <motion.button
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.98 }} 
                                className="flex text-3xl text-gray-600 dark:text-gray-300 items-baseline" 
                                onClick={() => setOpenOeeGoalModal(true)}>
                                {currentOee}% 
                                <span className='text-gray-500 dark:text-gray-400 text-xl pl-1'>OEE</span>
                            </motion.button>
                            <div className="flex items-center ml-4">
                                <span className={`text-lg ${isOeeImproved?'text-green-600':'text-red-600'}`}>{isOeeImproved &&'+'}{oeeDayChange}% </span>
                                <div className="my-auto px-0.5">
                                    <svg xmlns="http://www.w3.org/2000/svg" className={`w-5 h-5 transition delay-150 duration-300 ease-in-out ${isOeeImproved?'text-green-600':'text-red-600 rotate-180'}`} fill="currentColor" viewBox="0 0 16 16">
                                        <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                                    </svg>
                                </div>
                                <span className={`text-base ${isOeeImproved?'text-green-600':'text-red-600'}`}>today</span>
                                <span className={`text-sm pl-1 text-gray-600 dark:text-gray-500`}>({shiftForChart})</span>
                            </div>
                        </div>
                    </div> */}
                    <div >
                        <LineAreaCart chartData={oeeChartData} />
                    </div>
                    
                </div>
            </div>
            <div className='grid mt-2 grid-cols-2 gap-2 auto-rows-fr sm:hidden '>
                <div className='shadow-iotflows-lg rounded-lg px-1.5 py-5 flex flex-col min-w-[10px] text-center justify-center bg-slate-50/50 dark:bg-slate-800'>
                    <div className='text-3xl text-green-600 '>
                        {formatTime(assetInsights.total_running_seconds)}
                    </div>
                    <div className='text-xl text-gray-400 dark:text-gray-300'>
                        Uptime
                    </div>
                </div>
                <div className='shadow-iotflows-lg rounded-lg px-1.5 py-5 flex flex-col min-w-[10px] text-center justify-center bg-slate-50/50 dark:bg-slate-800 relative' onClick={() => setOpenOeeGoalModal(true)}>
                    <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.98 }}
                    >  
                        <div className='text-3xl '>
                            {renderOeeGoal(currentOee, orgOeeGoal)}
                        </div>
                        <div className='text-xl text-gray-400 dark:text-gray-300'>
                            Utilization
                        </div>

                    </motion.button>
                    <div className='absolute right-0 top-0 text-gray-500 dark:text-gray-400 z-10'>
                        <svg xmlns="http://www.w3.org/2000/svg" className='w-6 h-6' viewBox="0 -960 960 960"  fill="currentColor">
                            <path d="M280-280h120q17 0 28.5 11.5T440-240q0 17-11.5 28.5T400-200H240q-17 0-28.5-11.5T200-240v-160q0-17 11.5-28.5T240-440q17 0 28.5 11.5T280-400v120Zm400-400H560q-17 0-28.5-11.5T520-720q0-17 11.5-28.5T560-760h160q17 0 28.5 11.5T760-720v160q0 17-11.5 28.5T720-520q-17 0-28.5-11.5T680-560v-120Z"/>
                        </svg>
                    </div>
                </div>
                <div className='shadow-iotflows-lg rounded-lg p-1.5 flex flex-col min-w-[10px] text-center justify-center bg-slate-50/50 dark:bg-slate-800'>
                    <div className='text-3xl text-red-600 '>
                    {formatTime(assetInsights.total_not_running_seconds)}
                    </div>
                    <div className='text-xl text-gray-400 dark:text-gray-300'>
                        Downtime
                    </div>
                </div>
                <div className='shadow-iotflows-lg p-1 rounded-lg  flex flex-col min-w-[10px] text-center justify-center bg-slate-50/50 dark:bg-slate-800'>
                    <div className='flex items-baseline justify-center'>
                        <div className='text-3xl text-green-600'>
                            {assetInsights?.assets_producting_count}
                        </div>
                        <div className='text-2xl text-gray-500 dark:text-gray-400 px-1'>
                        {totalMachines ? `/` : ''}
                        </div>
                        <div className='text-2xl text-gray-500 dark:text-gray-400'>
                        {totalMachines ? totalMachines : ''}
                        </div>
                    </div>
                    <div className='flex flex-col text-xl text-gray-400 dark:text-gray-300'>
                        <span>Machines</span>
                        <span>Producing</span>
                    </div>
                </div>

            </div>
            <div className='hidden sm:grid grid-cols-1 mt-2 sm:grid-cols-2 lg:ml-2 lg:mt-0 auto-rows-fr '>
                <div className='shadow-iotflows-lg rounded-lg p-2 flex flex-col min-w-[220px] text-center justify-center sm:mr-2 mb-2 bg-slate-50/50 dark:bg-slate-800'>
                    <div className='text-4xl text-green-600 sm:pb-2'>
                        {formatTime(assetInsights.total_running_seconds)}
                    </div>
                    <div className='text-lg text-gray-400 dark:text-gray-300'>
                        Uptime
                    </div>
                </div>
                <div className='shadow-iotflows-lg rounded-lg p-2 flex flex-col min-w-[220px] text-center justify-center mb-2 bg-slate-50/50 dark:bg-slate-800 relative'>
                    <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.98 }}
                        onClick={() => setOpenOeeGoalModal(true)}
                        >
                        <div className='text-4xl  sm:pb-2'>
                            {renderOeeGoal(currentOee, orgOeeGoal)}
                        </div>
                        <div className='text-lg text-gray-400 dark:text-gray-300'>
                            Utilization
                        </div>
                    </motion.button>
                    <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.98 }}
                        onClick={() => setOpenOeeGoalModal(true)}
                        className='absolute right-0 top-0 text-gray-500 dark:text-gray-400 z-10'
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className='w-7 h-7' viewBox="0 -960 960 960"  fill="currentColor">
                                <path d="M280-280h120q17 0 28.5 11.5T440-240q0 17-11.5 28.5T400-200H240q-17 0-28.5-11.5T200-240v-160q0-17 11.5-28.5T240-440q17 0 28.5 11.5T280-400v120Zm400-400H560q-17 0-28.5-11.5T520-720q0-17 11.5-28.5T560-760h160q17 0 28.5 11.5T760-720v160q0 17-11.5 28.5T720-520q-17 0-28.5-11.5T680-560v-120Z"/>
                            </svg>
                    </motion.button>
                </div>
                <div className='shadow-iotflows-lg rounded-lg p-2 flex flex-col min-w-[220px] text-center justify-center sm:mr-2 mb-2 sm:mb-0 bg-slate-50/50 dark:bg-slate-800'>
                    <div className='text-4xl text-red-600 sm:pb-2'>
                    {formatTime(assetInsights.total_not_running_seconds)}
                    </div>
                    <div className='text-lg text-gray-400 dark:text-gray-300'>
                        Downtime
                    </div>
                </div>
                <div className='shadow-iotflows-lg rounded-lg p-2 flex flex-col min-w-[220px] text-center justify-center bg-slate-50/50 dark:bg-slate-800'>
                    <div className='flex items-baseline justify-center'>
                        <div className='text-4xl text-green-600'>
                            {assetInsights?.assets_producting_count}
                        </div>
                        <div className='text-3xl text-gray-500 dark:text-gray-400 px-1'>
                        {totalMachines ? `/` : ''}
                        </div>
                        <div className='text-3xl text-gray-500 dark:text-gray-400'>
                        {totalMachines ? totalMachines : ''}
                        </div>
                    </div>
                    {/* <div className='text-4xl text-green-600 sm:pb-2'>
                        {assetInsights?.assets_producting_count}
                    </div> */}
                    <div className='text-lg text-gray-400 dark:text-gray-300'>
                        Machines Producing
                    </div>
                </div>
            </div>
        </div>
        <div className='relative '>
            <div className={`${expand ? 'fixed top-[35px] left-0 right-0 overflow-scroll h-screen w-screen bg-white dark:bg-slate-900 z-50 px-4 py-2 ' : 'mt-2'} `}>
                <div className={` ${expand ? 'z-20 flex p-2' : 'hidden '} `}> 
                    <div className='hidden lg:space-x-2 lg:inline-flex'>
                        {/* <PartFilterBtn select={'overview'} /> */}
                        <DepartmentFilterBtn select={'overview'} />
                        <MachineTypeFilterBtn select={'overview'} />
                        <TagFilterBtn select={'overview'} />
                        <GroupByFilterBtn select={'overview'} />
                        <SortByFilterBtn select={'overview'} />
                        <ShiftFilterBtn select={'overview'} />
                    </div>
                    <div className='flex space-x-2 items-center ml-auto'>
                        
                        <div className='flex'>
                            <motion.button
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                transition={{ type: "spring", stiffness: 400, damping: 17 }} 
                                onClick={handleOpenSendLinkModal}
                                className='rounded-full text-gray-600 dark:text-gray-100 mr-2'
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 20.25h12m-7.5-3v3m3-3v3m-10.125-3h17.25c.621 0 1.125-.504 1.125-1.125V4.875c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125z" />
                                </svg>
                            </motion.button>
                            <ViewGridIcon className={`${view === 'card_view' && 'bg-gray-200 dark:bg-gray-700'} executeIcon rounded-l-lg rounded-r-none`} onClick={() => handleChangeUserView('card_view')}/>
                            <button className={`${view === 'card_large_view' && 'bg-gray-200 dark:bg-gray-700'} border-l-0 border-r-0 executeIcon rounded-none`} onClick={() => handleChangeUserView('card_large_view')}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 16.875h3.375m0 0h3.375m-3.375 0V13.5m0 3.375v3.375M6 10.5h2.25a2.25 2.25 0 0 0 2.25-2.25V6a2.25 2.25 0 0 0-2.25-2.25H6A2.25 2.25 0 0 0 3.75 6v2.25A2.25 2.25 0 0 0 6 10.5Zm0 9.75h2.25A2.25 2.25 0 0 0 10.5 18v-2.25a2.25 2.25 0 0 0-2.25-2.25H6a2.25 2.25 0 0 0-2.25 2.25V18A2.25 2.25 0 0 0 6 20.25Zm9.75-9.75H18a2.25 2.25 0 0 0 2.25-2.25V6A2.25 2.25 0 0 0 18 3.75h-2.25A2.25 2.25 0 0 0 13.5 6v2.25a2.25 2.25 0 0 0 2.25 2.25Z" />
                                </svg>
                            </button>
                            <button className={`${view === 'card_oee_large_view' && 'bg-gray-200 dark:bg-gray-700'} border-l-0 border-r-0 executeIcon rounded-none`} onClick={() => handleChangeUserView('card_oee_large_view')}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" viewBox="0 -960 960 960" fill="currentColor">
                                    <path d="M120-440v-320q0-33 23.5-56.5T200-840h240v400H120Zm240-80Zm160-320h240q33 0 56.5 23.5T840-760v160H520v-240Zm0 720v-400h320v320q0 33-23.5 56.5T760-120H520ZM120-360h320v240H200q-33 0-56.5-23.5T120-200v-160Zm240 80Zm240-400Zm0 240Zm-400-80h160v-240H200v240Zm400-160h160v-80H600v80Zm0 240v240h160v-240H600ZM200-280v80h160v-80H200Z"/>
                                </svg>
                            </button>
                            <MenuIcon className={`${view === 'list_view' && 'bg-gray-200 dark:bg-gray-700'} executeIcon rounded-r-lg rounded-l-none`} onClick={() => handleChangeUserView('list_view')} />
                        </div>
                        <AddAssetModal btnType='standardBtn'  />
                        <motion.div
                            className="box ml-auto mr-2"
                            onClick={() => handleExpandBtn(false)}
                            whileHover={{ scale: 1.2 }}
                            whileTap={{ scale: 0.9 }}
                            transition={{ type: "spring", stiffness: 400, damping: 17 }}
                        >
                            <XIcon className='w-6 h-6 text-gray-500' />
                        </motion.div> 
                    </div>
                    
                </div>
                <div className='rounded-full overflow-hidden' style={{height:2}}>
                    {loadingAssets &&
                        <LinearProgress color="primary" sx={{height:2}}/>
                    }
                </div>
                {isShowingAssets && view && view.length > 0 &&
                    showAssets(view, assetList, getOrgAssets, grayOutStatus, mqttMessageNodeUUID,mqttMessageIsRunning,  mqttMessageTimestamp, isUserAuthorized, isMobile)
                }
            </div>
        </div>
        
        {openOeeGoalModal &&
        <OeeGoalOrgModal remoteOpenModal={openOeeGoalModal} handleCloseModal={handleCloseOeeGoalOrgModal} getOrgAssets={getOrgAssets} />
        }
    </div>
  )
}

export default AssetsOverviewPage 

const showAssets = (view, assetList, getOrgAssets, grayOutStatus, mqttMessageNodeUUID, mqttMessageIsRunning, mqttMessageTimestamp, isUserAuthorized, isMobile) =>{
    const allLinks = {
        list_view: { content: <ListAssetView  assetList={assetList} getOrgAssets={getOrgAssets} grayOutStatus={grayOutStatus} mqttMessageNodeUUID={mqttMessageNodeUUID} mqttMessageIsRunning={mqttMessageIsRunning} mqttMessageTimestamp={mqttMessageTimestamp} isUserAuthorized={isUserAuthorized} isMobile={isMobile} /> },
        card_view: {  content:<CardAssetView assetList={assetList} getOrgAssets={getOrgAssets} grayOutStatus={grayOutStatus} mqttMessageNodeUUID={mqttMessageNodeUUID} mqttMessageIsRunning={mqttMessageIsRunning} mqttMessageTimestamp={mqttMessageTimestamp} isUserAuthorized={isUserAuthorized} isMobile={isMobile} /> },
        card_large_view: {  content:<CardAssetLargeView assetList={assetList} getOrgAssets={getOrgAssets} grayOutStatus={grayOutStatus} mqttMessageNodeUUID={mqttMessageNodeUUID} mqttMessageIsRunning={mqttMessageIsRunning} mqttMessageTimestamp={mqttMessageTimestamp} isUserAuthorized={isUserAuthorized} isMobile={isMobile} /> },
        card_oee_large_view: {  content:<CardAssetOeeLargeView assetList={assetList} getOrgAssets={getOrgAssets} grayOutStatus={grayOutStatus} mqttMessageNodeUUID={mqttMessageNodeUUID} mqttMessageIsRunning={mqttMessageIsRunning} mqttMessageTimestamp={mqttMessageTimestamp} isUserAuthorized={isUserAuthorized} isMobile={isMobile} /> },
    };
    

    return ( 
        <AnimatePresence mode='wait'>
        <motion.div key={view}
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -10, opacity: 0 }}
          transition={{ duration: 0.2 }}
          className='pb-10'
        >
            {allLinks[view].content} 
        </motion.div>
      </AnimatePresence>
    )  
}



