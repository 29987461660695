import { useLocation, useNavigate } from 'react-router-dom';
import ProgressGauge from "../components/charts/ProgressGauge";
import BarChartCard from "../components/charts/BarChartCard";
import { motion } from "framer-motion";
import { ArrowSmDownIcon, ArrowSmUpIcon } from "@heroicons/react/solid"
import StatusBar from '../components/charts/StatusBar';
import { Menu, Transition, MenuButton } from '@headlessui/react'
import { Fragment, useEffect, useRef, useState } from 'react'
import EditAssetModal from '../components/modals/assetmodals/EditAssetModal';
import DeleteAssetModal from '../components/modals/assetmodals/DeleteAssetModal';
import ArchiveAssetModal from '../components/modals/assetmodals/ArchiveAssetModal';
import { useRecoilValue } from "recoil";
import {globalSelectedOrganization} from '../atoms/orgStates'
import OptimizeLargeImage from '../components/OptimizeLargeImage';
import TimeAgo from 'timeago-react';
import { getData } from '../ApiCalls/DataApis';
import { apiLink } from '../ApiCalls/ApisVariables';
import moment from 'moment';
import { LightTooltip } from '../styles/Tooltip';
import { refreshOrganizationApis } from '../atoms/refreshStates'; 
import ScrapsBarChart from '../components/charts/ScrapsBarChart';
import toast from 'react-hot-toast';
import OeeGauge from '../components/charts/OeeGauge';
import CountGauge from '../components/charts/CountGauge';
import MultiCountGauge from '../components/charts/MultiCountGauge';

function CardComponent({asset,index, getOrgAssets, grayOutStatus, mqttMessageNodeUUID, mqttMessageTimestamp, isUserAuthorized, isMobile}) {
    const navigate = useNavigate();
    const selectedOrganization = useRecoilValue(globalSelectedOrganization)
    // const isOrganizationApisRefreshed = useRecoilValue(refreshOrganizationApis)
    const [isEditAssetModalOpen, setIsEditAssetModalOpen] = useState(false)
    const [isDeleteAssetModalOpen, setIsDeleteAssetModalOpen] = useState(false)
    const [isArchiveAssetModalOpen, setIsArchiveAssetModalOpen] = useState(false)
    const [uptime, setUptime] = useState('0:00h')
    const [downtime, setDowntime] = useState('0:00h')
    const [oeeCalc, setOeeCalc] = useState(0)
    const [barChartCategories, setBarChartCategories] = useState([])
    const [statusBarData, setStatusBarData] = useState([])
    const location = useLocation();
    const [assetActivity, setAssetActivity] = useState([])
    const [isAssetRunning, setIsAssetRunning] = useState(false)
    const [timePassed, setTimePassed] = useState(0)
    const [svgState, setSvgState] = useState(null)
    const [timePassedDate, setTimePassedDate] = useState('')
    const [opsList, setOpsList] = useState([])
    const [dateCategories, setDateCategories] = useState([])
    const [assetOeeGoal, setAssetOeeGoal] = useState('') 
    const [operation, setOperation] = useState([])

    const lastRefreshTimeRef = useRef(0);
    const refreshScheduledRef = useRef(false);

    useEffect(() => {
        try {
            if(mqttMessageNodeUUID == asset.node_uuid && mqttMessageTimestamp > 0 ) {
               
                handleRefreshLogic();
            }
            
        } catch (error) {
            
        }

    }, [mqttMessageTimestamp])

    function handleRefreshLogic() {
        const now = Date.now();
        const timeSinceLastRefresh = now - lastRefreshTimeRef.current;
    
        if (timeSinceLastRefresh >= 5000) {
          // Refresh immediately
          refreshAllApis();
          lastRefreshTimeRef.current = now;
          refreshScheduledRef.current = false;
        } else if (!refreshScheduledRef.current) {
          // Schedule refresh after remaining time
          const timeToWait = 5000 - timeSinceLastRefresh;
          refreshScheduledRef.current = true;
    
          setTimeout(() => {
            refreshAllApis();
            lastRefreshTimeRef.current = Date.now();
            refreshScheduledRef.current = false;
          }, timeToWait);
        }
      }


    useEffect(() => {
        try {
            if(asset && asset.asset_uuid){
                refreshAllApis()
            }
        } catch (error) {
            
        }

    }, [asset])

    const refreshAllApis = () => {
        try {
            if(asset && asset.asset_uuid){
                if(asset.asset_oee_goal){
                    setAssetOeeGoal(asset.asset_oee_goal)
                }

                let currentSearch = location.search
                getData(apiLink + '/v1/assets/'+ asset.asset_uuid + '/total-hourly-count' + currentSearch)
                .then(async response => {
                    // JSON data parsed by response.json() call
                    if(response.ok ){
                    const data = await response.json();
                        if(data && data.data){
                            let countData = []
                            let timeCategories = []
                            let lastDataIndex = Object.keys(data.data.total_hourly_count).length -1
                            data.data.total_hourly_count.map((hourCount, index) => {
                                let time = ''
                                if(index === 0) {
                                    time = formatAMPM(hourCount.hr)
                                    timeCategories.push(time)
                                }else if(index === lastDataIndex){
                                    time = formatAMPM(hourCount.hr)
                                    timeCategories.push(time)
                                }else{
                                    timeCategories.push(time)
                                }

                                let amount = 0
                                if(hourCount.total_operation_count){
                                    amount = hourCount.total_operation_count
                                }
                                let array = [formatAMPM(hourCount.hr), amount]
                                countData.push(array)
                            })
                            setBarChartCategories(timeCategories)
                        }else{
                            setBarChartCategories([])
                        }
                    }else{
                        setBarChartCategories([])
                    }
                })

                // let currentSearch = location.search
                if(currentSearch){
                    currentSearch += '&time_interval_min=60'
                }else{
                    currentSearch = '?time_interval_min=60'
                }
                
                    getData(apiLink + '/v2/assets/'+ asset.asset_uuid + '/operations_made_by_interval' + currentSearch  )
                    .then(async response => {
                        // JSON data parsed by response.json() call
                        const data = await response.json();
                        if(response.ok ){
                            if(data && data.data ){
                                setOpsList(data.data[0])
                                if(data.data[0] && data.data[0].operations && data.data[0].operations[0] && data.data[0].operations[0].operations_made && Object.keys(data.data[0].operations[0].operations_made).length > 0){
                                    let operations = data.data[0].operations[0]
                                    let timeCategoriesAll = []
                                    let timeCategoriesTrimmed = []
                                    let lastDataIndex = Object.keys(operations.operations_made).length -1
                                    
                                    operations.operations_made.map((hourCount, index) => {
                                        let time = ''
                                        if(index === 0) {
                                            time = formatAMPM(hourCount.interval_timestamp)
                                            timeCategoriesTrimmed.push(time)
                                        }else if(index === lastDataIndex){
                                            time = formatAMPM(hourCount.interval_timestamp)
                                            timeCategoriesTrimmed.push(time)
                                        }else{
                                            timeCategoriesTrimmed.push(time)
                                        }
            
                                        let currentTime = formatAMPM(hourCount.interval_timestamp)
                                        timeCategoriesAll.push(currentTime)
            
                                    })
                                    setDateCategories(timeCategoriesAll)
                                    let currentOperation = data.data[0].operations[0]
                                    setOperation(currentOperation)
                                }
        
                            }else{
                                setOpsList([])
                                setOperation([])
                            }
                        }else{
                            toast.error(data.message)
                            setOpsList([])
                            setOperation([])
                        }
                    })
                    
                getData(apiLink + '/v1/assets/'+ asset.asset_uuid + '/shift-utilizations-operations-svg' + currentSearch)
                .then(async response => {
                    // JSON data parsed by response.json() call
                    if(response.ok ){
                    const data = await response.json();
                        if(data && data.data) {                   
                            let imageSvg = `data:image/svg+xml;charset=utf-8;base64,` + btoa(data.data.shift_states_svg)
                            let svgBitMap = await convertSvgToBitmap(imageSvg)
                            if(svgBitMap.length < 10){
                                setSvgState(imageSvg)
                            }else{
                                setSvgState(svgBitMap)
                            }

                            let uptimeString  = formatTime(data.data.runtime_shift)
                            let downtimeString  = formatTime(data.data.downtime_shift)

                            setUptime(uptimeString)
                            setDowntime(downtimeString)

                            let totalTime = data.data.runtime_shift + data.data.downtime_shift
                            let oee = (data.data.runtime_shift/totalTime)
                            setOeeCalc(oee)
                            
                        }else{
                            setUptime('0:00h')
                            setDowntime('0:00h')
                            setOeeCalc(0)
                            setSvgState(null)
                        }
                    }else{
                        setUptime('0:00h')
                        setDowntime('0:00h')
                        setOeeCalc(0)
                        setSvgState(null)
                    }
                })


                getData(apiLink + '/v1/assets/'+ asset.asset_uuid + '/activity-state' + currentSearch)
                .then(async response => {
                    // JSON data parsed by response.json() call
                    if(response.ok ){
                    const data = await response.json();
                        if(data && data.data){
                            setAssetActivity(data.data)
                            let timePassed = 0
                            const lastOpDate = new Date(data.data.activity_finished_at)
                            const lastOpSecs = lastOpDate.getTime()
                    
                            const currenDate = new Date()
                            const currentSecs = currenDate.getTime()
                    
                            timePassed = (currentSecs - lastOpSecs)/1000
                            setTimePassed(timePassed)
                            setTimePassedDate(data.data.activity_finished_at)
                            
                            if(data.data.state ==='running' || timePassed <= 300){
                                setIsAssetRunning(true)
                            }else{
                                setIsAssetRunning(false)
                            }
                        }else{
                            setAssetActivity([])
                        }
                    }else{
                        setAssetActivity([])
                    }
                })

            }
        } catch (error) {
            
        }

    }

    

    const convertSvgToBitmap = async (svg) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = svg;
      
            img.onload = () => {
              const canvas = document.createElement('canvas');
              canvas.width = img.width;
              canvas.height = img.height;
      
              const ctx = canvas.getContext('2d');
              ctx.drawImage(img, 0, 0);
      
              const bitmapDataUrl = canvas.toDataURL('image/png'); // You can change 'image/png' to 'image/jpeg' if needed
              resolve(bitmapDataUrl) 
            //   setBitmap(bitmapDataUrl);
            };
      
            img.onerror = (error) => {
                console.error('Error loading SVG image:', error);
                reject(error)
            };
        })
      };



    const formatTime =(seconds) => {
        let timeString = ''
        if(seconds){
            let totalMin = seconds/60
            let hours = totalMin/60
            hours = Math.floor(hours)
            let remMinutes = (totalMin - (hours*60))
            remMinutes = Math.floor(remMinutes)
            if(hours < 10 && hours >= 0){
                hours = '0' + hours 
            }
            if(remMinutes < 10 && remMinutes >= 0){
                remMinutes = '0' + remMinutes 
            }

            timeString = `${hours}:${remMinutes}h`



            // let formattedUptime  = moment.utc(seconds*1000).format('HH:mm')
            // let timeString = `${formattedUptime}h`
            // return timeString
        }else{
            timeString = `00:00h`
            
        }
        return timeString
    }

    function formatAMPM(date) {
        let event = new Date(date)
        let currentTime = event.toLocaleTimeString('en-US', { hour: '2-digit'})
        currentTime = currentTime.replace(/\s/g, '')
        if(currentTime[0] == '0'){
            currentTime = currentTime.substring(1)
        }

        return currentTime
      }

    
    const handleOpenEditAssetModal = () => {
        setIsEditAssetModalOpen(false)
    }
    const handleOpenDeleteAssetModal = () => {
        setIsDeleteAssetModalOpen(false)
    }
    const handleCloseArchiveAssetModal = () => {
        setIsArchiveAssetModalOpen(false)
    }

    const handleNavigation = () => {
        if(isMobile){
            navigate(`/${selectedOrganization}/assets/selected-asset/${asset.asset_uuid}?platform=mobile`)
        }else{
            navigate(`/${selectedOrganization}/assets/selected-asset/${asset.asset_uuid}`)
        }
    }


  return (
    <div>
        <motion.div  whileHover={{ scale: 1.01 }} whileTap={{ scale: 0.98 }} key={index} className='relative z-0 shadow-iotflows-lg grow rounded-lg overflow-hidden bg-slate-50/50 dark:bg-slate-800 mt-2 w-full cursor-pointer sm:min-w-[400px]'>
            <div className='absolute top-0 right-0 z-50'>
                <Menu as="div" className="relative text-left">
                    <div>
                    <MenuButton 
                        className="inline-flex w-full justify-center text-center rounded-full bg-transparent p-0.5 text-sm font-medium hover:bg-gray-100 dark:hover:bg-gray-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 disabled:hover:bg-transparent disabled:dark:hover:bg-transparent"
                        disabled={!isUserAuthorized}
                        >
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-600 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300 " aria-hidden="true" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                        </svg>
                    </MenuButton>
                    </div>
                    <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                    >
                    <Menu.Items className="absolute right-0 w-40 origin-top-right rounded-md bg-white dark:bg-slate-700 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="px-1 py-1 ">
                            <Menu.Item>
                                {({ active }) => (
                                <button
                                    className={`${
                                    active ? 'bg-blue-500 dark:bg-blue-700 text-white' : 'text-gray-900 dark:text-gray-300'
                                    } group flex w-full items-center rounded-md px-2 py-2 text-sm disabled:opacity-50`}
                                    onClick={()=> setIsEditAssetModalOpen(true)}
                                    disabled={!isUserAuthorized}
                                >
                                    {active ? (
                                    <EditActiveIcon
                                        className="mr-2 h-5 w-5"
                                        aria-hidden="true"
                                    />
                                    ) : (
                                    <EditInactiveIcon
                                        className="mr-2 h-5 w-5"
                                        aria-hidden="true"
                                    />
                                    )}
                                    Edit
                                </button>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                <button
                                    className={`${
                                    active ? 'bg-blue-500 dark:bg-blue-700 text-white' : 'text-gray-900 dark:text-gray-300'
                                    } group flex w-full items-center rounded-md px-2 py-2 text-sm disabled:opacity-50`}
                                    onClick={()=> setIsArchiveAssetModalOpen(true)}
                                    disabled={!isUserAuthorized}
                                >
                                    {active ? (
                                    <ArchiveActiveIcon
                                        className="mr-2 h-5 w-5 text-gray-900 dark:text-gray-300"
                                        aria-hidden="true"
                                    />
                                    ) : (
                                    <ArchiveInactiveIcon
                                        className="mr-2 h-5 w-5 text-gray-900 dark:text-gray-300"
                                        aria-hidden="true"
                                    />
                                    )}
                                    Archive
                                </button>
                                )}
                            </Menu.Item>
                        </div>
                        <div className="px-1 py-1 border-t-[0.01em] dark:border-slate-600">
                            {Object.keys(assetActivity).length > 0  ? (
                                <LightTooltip title="This asset can't be deleted because it has been used in production and has operation data attached to it. If you are no longer using this asset, you can archive it instead." >
                                    <div
                                        className={`flex w-full items-center rounded-md px-2 py-2 text-sm text-gray-900 dark:text-gray-400`}
                                    >
                                        <DeleteInactiveIcon
                                            className="mr-2 h-5 w-5 text-gray-900 dark:text-gray-500"
                                            aria-hidden="true"
                                        />
                                        <span>Delete</span>
                                    </div>
                                </LightTooltip> 

                            ):(
                            <Menu.Item>
                                {({ active }) => (
                                <button
                                    className={`${
                                    active ? 'bg-blue-500 dark:bg-blue-700 text-white' : 'text-gray-900 dark:text-gray-300'
                                    } group flex w-full items-center rounded-md px-2 py-2 text-sm disabled:opacity-50`}
                                    onClick={()=> setIsDeleteAssetModalOpen(true)}
                                    disabled={!isUserAuthorized}
                                >
                                    {active ? (
                                    <DeleteActiveIcon
                                        className="mr-2 h-5 w-5 text-blue-400"
                                        aria-hidden="true"
                                    />
                                    ) : (
                                    <DeleteInactiveIcon
                                        className="mr-2 h-5 w-5 text-blue-400"
                                        aria-hidden="true"
                                    />
                                    )}
                                    Delete
                                </button>
                                )}
                            </Menu.Item>
                            )}
                        </div>
                    </Menu.Items>
                    </Transition>
                </Menu>
            </div>
            <div className='flex flex-col sm:hidden' onClick={handleNavigation}>
                <div className='flex'>
                    <div className='flex z-0 '>
                        <div className=' px-4 py-2'>
                            {grayOutStatus ? (
                                <div className={`border-8 border-gray-300 dark:border-gray-500 rounded-full bg-white z-10 h-28 w-28`}>
                                        <OptimizeLargeImage alt={asset.machine_name} source={asset.asset_custom_picture_url  ? asset.asset_custom_picture_url : asset.machine_picture_url} size='xl' />
                                </div>
                            ):(
                                <div className={`border-8 ${isAssetRunning? 'border-green-600':'border-red-600'} rounded-full bg-white z-10 h-28 w-28`}>
                                        <OptimizeLargeImage alt={asset.machine_name} source={asset.asset_custom_picture_url  ? asset.asset_custom_picture_url : asset.machine_picture_url} size='xl' />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='flex flex-col grow border-l-2 border-gray-200 dark:border-gray-700'>
                        <div className='flex justify-between pb-1 pr-2'>
                            <div className='flex flex-col w-[170px] ' >
                                <div className='pt-2 pl-2 '>
                                    <div className='text-left truncate text-base text-gray-600 dark:text-white font-normal'>{asset.asset_custom_name  ? asset.asset_custom_name :asset.machine_name}</div>
                                    <div className='text-left truncate text-sm text-gray-400 dark:text-gray-200 font-medium'>{asset.asset_custom_identifier  ? asset.asset_custom_identifier :asset.asset_uuid}</div>
                                    <div className='flex justify-between pb-1 w-[148px]'>
                                        <div className='text-lg text-green-600 font-medium flex items-center' >
                                            {uptime}
                                            <ArrowSmUpIcon className='h-5 w-5 text-green-600' />
                                        </div>
                                        <div className='text-lg text-red-600 font-medium flex items-center' >
                                            {downtime}sss
                                            <ArrowSmDownIcon className='h-5 w-5 text-red-600' />
                                        </div>
                                    </div> 
                                    {assetActivity && Object.keys(assetActivity).length > 0 && !grayOutStatus &&
                                        setLastOpTime(asset, assetActivity, timePassed, timePassedDate)
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex py-2 justify-around border-t-2 border-gray-200 dark:border-gray-700'>
                    <div className=''>
                        <OeeGauge unit={'OEE'} oeeValue={oeeCalc} goal={assetOeeGoal} />
                    </div>
                    <div >
                        {opsList && opsList.operations && Object.keys(opsList.operations).length > 1 ? (
                            <MultiCountGauge opsList={opsList} />
                        ):(
                            <CountGauge unit={'made'} operation={operation} />
                        )}
                    </div>
                </div>
                <div className='flex border-t-2 border-gray-200 dark:border-gray-700'>
                    <div className='grow'>
                        {(operation && Object.keys(operation).length > 0) && (
                            <ScrapsBarChart key={operation.operation_uuid} height='90px'  countArray={operation.operations_made} hourlyGoal={operation.asset_operation_hourly_goal_count} dateCategories={dateCategories}/>
                        )}
                    </div>
                </div>
            </div>
            <div className='hidden sm:flex' onClick={handleNavigation}>
                <div className='flex flex-col z-0 w-[148px] xl:w-[170px]'>
                    <div className=' px-4 pt-5'>
                    {grayOutStatus ? (
                        <div className={`border-8 border-gray-300 dark:border-gray-500 rounded-full bg-white z-10 h-28 w-28`}>
                                <OptimizeLargeImage alt={asset.machine_name} source={asset.asset_custom_picture_url  ? asset.asset_custom_picture_url : asset.machine_picture_url} size='xl' />
                        </div>
                    ):(
                        <div className={`border-8 ${isAssetRunning? 'border-green-600':'border-red-600'} rounded-full bg-white z-10 h-28 w-28`}>
                                <OptimizeLargeImage alt={asset.machine_name} source={asset.asset_custom_picture_url  ? asset.asset_custom_picture_url : asset.machine_picture_url} size='xl' />
                        </div>
                    )}
                    </div>
                    <div className='flex justify-between pt-1 px-1 w-[148px] xl:w-[170px]'>
                        <div className='text-base xl:text-lg  text-green-600 font-medium flex items-center' >
                            {uptime}
                            <ArrowSmUpIcon className='h-5 w-5 text-green-600' />
                        </div>
                        <div className='text-base xl:text-lg  text-red-600 font-medium flex items-center' >
                            {downtime}
                            <ArrowSmDownIcon className='h-5 w-5 text-red-600' />
                        </div>
                    </div>
                </div>
                <div className='flex flex-col grow border-l-2 border-gray-200 dark:border-gray-700'>
                    <div className='flex justify-between pb-1 pr-2'>
                        <div className='flex flex-col w-[165px]' >
                            <div className='pt-2 pl-2 '>
                                <div className='text-left leading-6 truncate text-lg text-gray-600 dark:text-white font-normal'>{asset.asset_custom_name  ? asset.asset_custom_name :asset.machine_name}</div>
                                <div className='text-left leading-6 truncate text-base text-gray-400 dark:text-gray-200 font-medium'>{asset.asset_custom_identifier  ? asset.asset_custom_identifier :asset.asset_uuid}</div>
                                {assetActivity && Object.keys(assetActivity).length > 0 && !grayOutStatus &&
                                    setLastOpTime(asset, assetActivity, timePassed, timePassedDate)
                                } 
                                

                            </div>
                        </div>
                        <div className='ml-auto'>
                            <OeeGauge unit={'OEE'} oeeValue={oeeCalc} goal={assetOeeGoal} />
                        </div>
                    </div>
                    <div className='flex justify-between border-t-2 border-gray-200 dark:border-gray-700 pr-2'>
                        <div className='grow h-[90px]'>
                            {(operation && Object.keys(operation).length > 0) && (
                                <ScrapsBarChart key={operation.operation_uuid} height='90px' countArray={operation.operations_made} hourlyGoal={operation.asset_operation_hourly_goal_count} dateCategories={dateCategories}/>
                            )}
                        </div>
                        <div >
                            {(opsList && opsList.operations && Object.keys(opsList.operations).length > 1) && (
                                <MultiCountGauge opsList={opsList} />
                            )}
                            {(operation && Object.keys(operation).length > 0 && opsList && opsList.operations && Object.keys(opsList.operations).length == 1) && (
                            <CountGauge unit={'made'} operation={operation} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div onClick={handleNavigation} className='h-4'>
                <StatusBar statusBarData={statusBarData} barChartCategories={barChartCategories} />
            </div>
            <div onClick={handleNavigation} className='h-2'>
            {svgState &&
                    <img src={svgState} className='h-2'/>
                }
                </div>
        </motion.div>
        {isEditAssetModalOpen && 
            <EditAssetModal remoteOpenAssetModal={isEditAssetModalOpen} handleOpenEditAssetModal={handleOpenEditAssetModal} asset={asset} btnType='hideBtn' getOrgAssets={getOrgAssets} />
        }
        {isArchiveAssetModalOpen && 
            <ArchiveAssetModal remoteOpenModal={isArchiveAssetModalOpen} handleCloseArchiveAssetModal={handleCloseArchiveAssetModal} asset={asset} getOrgAssets={getOrgAssets} />
        }
        {isDeleteAssetModalOpen && 
            <DeleteAssetModal remoteOpenModal={isDeleteAssetModalOpen} handleOpenDeleteAssetModal={handleOpenDeleteAssetModal} asset={asset} getOrgAssets={getOrgAssets} />
        }
    </div>
  )
}

export default CardComponent

const setLastOpTime = (asset, assetActivity, timePassed, timePassedDate) => {
    if(asset && Object.keys(asset).length > 0){
       
    
        if(assetActivity.state === 'running'){
            return (
                <div className='h-6 min-w-[181px] bg-green-600 w-full rounded-tl-full rounded-r-full ml-[-60px] xl:ml-[-70px] text-right text-white dark:text-white font-medium text-xs items-center p-1  '>
                    Running&nbsp;
                </div>
            )
        }else if(assetActivity.state === 'stopped'){
            if(timePassed <= 300){
                return (
                    <div className='h-6 min-w-[181px] bg-green-600 w-full rounded-tl-full rounded-r-full ml-[-60px] xl:ml-[-70px] text-right text-white dark:text-white font-medium text-xs items-center p-1  '>
                        Last Op:&nbsp;<TimeAgo datetime={timePassedDate}  />
                    </div>
                )
            }else{
                return (
                    <div className='h-6 min-w-[181px] bg-red-600 w-full rounded-tl-full rounded-r-full ml-[-60px] xl:ml-[-70px] text-right text-white dark:text-white font-medium text-xs items-center p-1  '>
                        Last Op:&nbsp;<TimeAgo datetime={timePassedDate}  />
                    </div>
                )

            }
        }

    }

}


function EditInactiveIcon(props) {
    return (
        <svg
        {...props}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        >
        <path
            d="M4 13V16H7L16 7L13 4L4 13Z"
            fill="transparent"
            stroke="#94a3b8"
            strokeWidth="2"
        />
        </svg>
    )
}

function EditActiveIcon(props) {
    return (
        <svg
        {...props}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        >
        <path
            d="M4 13V16H7L16 7L13 4L4 13Z"
            fill="transparent"
            stroke="#a5b4fc"
            strokeWidth="2"
        />
        </svg>
    )
}

function DeleteInactiveIcon(props) {
    return (
      <svg
        {...props}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="5"
          y="6"
          width="10"
          height="10"
          fill="transparent"
          stroke="#94a3b8"
          strokeWidth="2"
        />
        <path d="M3 6H17" stroke="#94a3b8" strokeWidth="2" />
        <path d="M8 6V4H12V6" stroke="#94a3b8" strokeWidth="2" />
      </svg>
    )
  }
  
  function DeleteActiveIcon(props) {
    return (
      <svg
        {...props}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="5"
          y="6"
          width="10"
          height="10"
          fill="transparent"
          stroke="#a5b4fc"
          strokeWidth="2"
        />
        <path d="M3 6H17" stroke="#a5b4fc" strokeWidth="2" />
        <path d="M8 6V4H12V6" stroke="#a5b4fc" strokeWidth="2" />
      </svg>
    )
  }
function ArchiveInactiveIcon(props) {
    return (
        <svg 
        {...props} 
        xmlns="http://www.w3.org/2000/svg" 
        fill="none" viewBox="0 0 24 24" 
        strokeWidth={2} 
        stroke="#94a3b8"
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
        </svg>
    )
  }
  
  function ArchiveActiveIcon(props) {
    return (
      <svg 
        {...props} 
        xmlns="http://www.w3.org/2000/svg" 
        fill="none" viewBox="0 0 24 24" 
        stroke="#a5b4fc"
        strokeWidth="2"
        >
        <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
    </svg>

    )
  }