import { useEffect, useState } from "react"
import { motion } from "framer-motion";
import { NavLink, useSearchParams } from "react-router-dom";


function SubNav({subNav, selectedSubLink}) {
    const [selectedTab, setSelectedTab] = useState(selectedSubLink);
    const [isMobile, setIsMobile] = useState(false)
    const [showMobileNav, setShowMobileNav] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
      let currentIsMobile = searchParams.get('platform')
      let currentSelect = searchParams.get('select')
      if(currentIsMobile === 'mobile'){
        setIsMobile(true)
        if(currentSelect === 'parts_list' || currentSelect === 'part' || currentSelect === 'historical_report' ){
          setShowMobileNav(true)
        }
      }else{
        setIsMobile(false)
        setShowMobileNav(false)
      }
    },[])


    useEffect(() =>{
        setSelectedTab(selectedSubLink)
    },[selectedSubLink])

    
    
    
    return (
        <nav className={`${showMobileNav && showMobileNav && 'mx-2'}`}>
            <div className={`flex ${isMobile && !showMobileNav && 'hidden'} ${isMobile && showMobileNav  ? 'w-full justify-between ': 'w-fit'}  overflow-x-scroll scrollbar-hide space-x-2 md:space-x-4 border-b border-blue-600 `}>
            {/* <div className={`${isMobile && 'hidden'} flex overflow-x-scroll scrollbar-hide space-x-2 md:space-x-4 border-b border-blue-600 w-fit`}> */}
            {Object.keys(subNav).map((item) => (
                <NavLink
                key={subNav[item].url}
                to={{
                    pathname: subNav[item].pathName,
                    search: isMobile ? `${subNav[item].search}&platform=mobile`:subNav[item].search,
                  }}
                className={`relative ${isMobile ? 'pt-1.5 pb-1 ':' pt-0.5 pb-1.5'}`}
                onClick={() => setSelectedTab(subNav[item])}
                >
                <span className={`${subNav[item].url === selectedTab.url ? 'font-medium':' font-base'} ${isMobile && 'text-base'} mr-1 pb-1 text-gray-600 dark:text-gray-100 hover:text-gray-900 dark:hover:text-gray-300 outline-none text-[13px] cursor-pointer bg-transparent whitespace-nowrap`}>
                  {`${subNav[item].name}`}
                </span>
                {subNav[item].url === selectedTab.url ? (
                    <motion.div className="underline" layoutId="underline" />
                ) : null}
                </NavLink>
            ))}
            </div>
        </nav>
    )
}

export default SubNav
