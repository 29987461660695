import React, { useEffect, useState } from 'react'
import ParetoChartDowntimes from '../../components/charts/ParetoChartDowntimes'
import DepartmentFilterBtn from '../../components/filterButtons/DepartmentFilterBtn';
import DowntimeSeverityFilterBtn from '../../components/filterButtons/DowntimeSeverityFilterBtn';
import DateRangeFilter from '../../components/filterButtons/DateRangeFilter';
import DowntimeWithNotesFilterBtn from '../../components/filterButtons/DowntimeWithNotesFilterBtn';
import DowntimeClassificationTable from './DowntimeClassificationTable';
import { globalSelectedOrganization, globalSelectedOrganizationTimezone } from '../../atoms/orgStates';
import { useRecoilValue } from 'recoil';
import { getData } from '../../ApiCalls/DataApis';
import { useLocation, useSearchParams } from 'react-router-dom';
import { apiLink } from '../../ApiCalls/ApisVariables';
import { LinearProgress } from '@mui/material';
import ExcludeNoneSwitch from '../../components/filterButtons/AdvanceReportingFilterBtns/ExcludeNoneSwitch';
import ShiftSelectionFilterBtn from '../../components/filterButtons/AdvanceReportingFilterBtns/ShiftSelectionFilterBtn';
import SortByFilterBtn from '../../components/filterButtons/SortByFilterBtn';
import DepartmentMachineFilterBtn from '../../components/filterButtons/AdvanceReportingFilterBtns/DepartmentMachineFilterBtn';
import DateRangeFilterAdvancedBtn from '../../components/filterButtons/AdvanceReportingFilterBtns/DateRangeFilterAdvancedBtn';
import StackedBarChartDowntimes from '../../components/charts/DowntimeCharts/StackedBarChartDowntimes';



function DowntimeClassificationsPage() {
    const location = useLocation()
    const selectedOrganization = useRecoilValue(globalSelectedOrganization);
    const selectedOrganizationTimeZone = useRecoilValue(globalSelectedOrganizationTimezone);
    const [downtimePareto, setDowntimePareto] = useState([])
    const [assetsDowntime, setAssetsDowntime] = useState([])
    const [intervalDowntime, setIntervalDowntime] = useState([])
    const [loadingPareto, setLoadingPareto] = useState(false)
    const [loadingDowntimeClassifications, setLoadingDowntimeClassifications] = useState(false)
    const [logsList, setLogsList] = useState([])
    const [searchParams, setSearchParams] = useSearchParams()

    
    

    useEffect(() => {
        if(selectedOrganization){
          let currentSearch = location.search
            try{
                setDowntimePareto([])
                setAssetsDowntime([])
                setIntervalDowntime([])
                setLogsList([])
              let fromDate = searchParams.get('from')
              let toDate = searchParams.get('to')
              let period = searchParams.get('time_period')
              // forcing to have a date in the url to pull date, this is because of how long it takes the 6 weeks to load
              if(period != null && toDate != null && fromDate != null){
                
                
                if(! loadingDowntimeClassifications){
                  setLoadingDowntimeClassifications(true)
                  getData(apiLink + '/v2/organizations/' + selectedOrganization + '/downtimes' + currentSearch)
                  // getData(apiLink + '/v1/organizations/' + selectedOrganization + '/downtimes' + currentSearch)
                  .then(async response => {
                      const data = await response.json();
                    if(response && response.ok ){
                      if(data && data.data){
                        setLogsList(data.data)
                        setLoadingDowntimeClassifications(false)
                      } 
                    }
                  });
                }
                if(!loadingPareto){
                  setLoadingPareto(true)
                  getData(apiLink + '/v2/organizations/' + selectedOrganization + '/downtimes/summary' + currentSearch)
                  // getData(apiLink + '/v1/organizations/' + selectedOrganization + '/downtimes/summary' + currentSearch)
                  .then(async response => {
                      const data = await response.json();
                    //   console.log('downtimes/summary', data)
                    if(response.ok ){
                        if(data && data.data){
                            setDowntimePareto(data.data[0].pareto[0])
                            setAssetsDowntime(data.data[0].by_asset[0])
                            setIntervalDowntime(data.data[0].by_interval[0])
                            // setDowntimeSummary(data.data)
                            setLoadingPareto(false)
                        }else{
                            setLoadingPareto(false)
                        }
                    }
                    });
                }
              }
            }catch (e){
                setLoadingPareto(false)
              console.log(e.message)
            }
        }
  
      }, [selectedOrganization, location])

  return (
    <div className='mt-2 p-1 h-screen'>
        <div className='flex flex-col sm:flex-row sm:items-center'>
            <div className='flex space-x-2'>
                {/* <DepartmentFilterBtn select={'downtimes'} /> */}
                <DepartmentMachineFilterBtn select='downtimes' />
                <ShiftSelectionFilterBtn select='downtimes' />
                <DowntimeSeverityFilterBtn select={'downtimes'} />
                <SortByFilterBtn select={'downtimes'} />
            </div>
            <div className='flex ml-auto '>
                <DowntimeWithNotesFilterBtn  />
                <div className='ml-2'>
                    {/* <DateRangeFilter /> */}
                    <DateRangeFilterAdvancedBtn /> 
                </div> 
            </div>
        </div>
        {/* <div  className='shadow-iotflows-lg rounded-[28px] dark:bg-slate-800 mt-2 py-4'>
                {loadingPareto &&
                    <div className='rounded-full'>
                        <LinearProgress color="primary" sx={{height:2}}/>
                    </div>
                    }
            <div className='flex px-2 '>
                <div className='grow'>
                    <ParetoChartDowntimes downtimeSummary={downtimeSummary}  />
                </div>
            </div>
        </div> */}
        <div className='py-1'>
            <p className='text-gray-600 dark:text-gray-300 italic text-sm font-light'>{`This report was ran on the organizations time zone of ${selectedOrganizationTimeZone.name}, dates and times may vary from your local time zone.`}</p>
        </div>
        <div className='rounded-full' style={{height:2}}>
            {loadingPareto &&
                <LinearProgress color="primary" sx={{height:2}}/>
            }
        </div>
        <div  className='shadow-iotflows-lg rounded-[28px] dark:bg-slate-800 mt-2 pt-4 pb-0'>
            <div className='flex px-2 '>
                <div className='grow'>
                    <ParetoChartDowntimes downtimeSummary={downtimePareto} />
                </div>
            </div>
        </div>
        <div  className='shadow-iotflows-lg rounded-[28px] dark:bg-slate-800 mt-2 pt-4 pb-0'>
            <div className='flex px-2 '>
                <div className='grow'>
                    <StackedBarChartDowntimes downtimeSummary={intervalDowntime} title={`Downtimes by Time Interval`}  />
                </div>
            </div>
        </div>
        <div  className='shadow-iotflows-lg rounded-[28px] dark:bg-slate-800 mt-2 pt-4 pb-0'>
            <div className='flex px-2 '>
                <div className='grow'>
                    <StackedBarChartDowntimes downtimeSummary={assetsDowntime} title={`Downtimes by Asset`}  />
                </div>
            </div>
        </div>
        <div className='mt-4 pb-10'>
            <DowntimeClassificationTable data={logsList} rowsPerPage={25} loading={loadingDowntimeClassifications}  />
        </div>
    </div>
  )
}

export default DowntimeClassificationsPage